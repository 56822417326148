import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RecognizedItem } from '@/constants';

import { TTSApiRequest } from '../../features/api/ttsApi';
import type { RootState } from '../store';

/**
 * マイク認識状態
 */
export const MIC_STATUS = {
  // 認識前
  NONE: 'none',
  // 認識成功
  SUCCESS: 'success',
  // 変更中
  CHANGING: 'changing',
  // 認識失敗
  ERROR: 'error',
};
export type MicStatus = (typeof MIC_STATUS)[keyof typeof MIC_STATUS];

/**
 * 音声認識(収録)状況
 */
export const STT_STATUS = {
  // 何もしていない
  INACTIVE: 'inactive',
  // 準備中
  READY: 'ready',
  // Websocketを介して音声ストリームに接続中
  CONNECTING: 'connecting',
  // 停止中
  PAUSED: 'paused',
  // エラー
  ERROR: 'error',
};
export type STTStatus = (typeof STT_STATUS)[keyof typeof STT_STATUS];

/**
 * 音声合成(読み上げ)状況
 */
export const TTS_STATUS = {
  // 何もしていない
  INACTIVE: 'inactive',
  // 読み上げ中
  READING: 'reading',
  // 停止中
  PAUSED: 'paused',
};
export type TTSStatus = (typeof TTS_STATUS)[keyof typeof TTS_STATUS];

/**
 * 失敗理由
 */
export const STT_ERROR_TYPE = {
  // 無し
  NONE: 'none',
  // アクセス期限切れ
  ACCESS_EXP: 'accessExp',
  // PTID期限切れ
  PTID_EXP: 'ptidExp',
  // 利用規約が更新されている
  NEED_AGREEMENT: 'needAgreement',
  // その他エラー
  OTHER: 'other',
};
export type SttErrorType = (typeof STT_ERROR_TYPE)[keyof typeof STT_ERROR_TYPE];

/**
 * TTSするためのリクエスト情報
 */
export type TTSRequestInfo = {
  // 一意なID
  id: string;
  // TTS情報
  ttsRequest: TTSApiRequest;
};

/**
 * リトライ状態
 */
export const RETRY_STATUS = {
  // 無し
  NONE: 'none',
  // リトライ開始
  RETRY: 'retry',
  // リトライ中
  RETRYING: 'retrying',
};
export type RetryStatus = (typeof RETRY_STATUS)[keyof typeof RETRY_STATUS];

/**
 * State
 */
export type TranslationInfoState = {
  // ライセンストークン(ライセンストークン発行APIから返却された値)
  licenseToken: string;
  // マイク認識状況
  micStatus: MicStatus;
  // 音声認識(収録)状況
  sttStatus: STTStatus;
  // 音声合成(読み上げ)状況
  ttsStatus: TTSStatus;
  // リトライ状態
  retryStatus: RetryStatus;
  // 翻訳画面の表示内容(翻訳リスト)
  recognizedList: EntityState<RecognizedItem>;
  // 失敗理由
  sttErrorType: SttErrorType;
  // TTSリクエスト情報格納キュー
  ttsRequestQueue: EntityState<TTSRequestInfo>;
  // TTSの音声ファイルURL格納キュー
  audioUrlQueue: EntityState<string>;
};

export const recognizedListAdapter = createEntityAdapter<RecognizedItem>({
  selectId: (item) => item.id,
});

export const recognizedListAdapterSelectors =
  recognizedListAdapter.getSelectors(
    (state: RootState) => state.translationInfo.recognizedList,
  );

export const ttsRequestQueueAdapter = createEntityAdapter<TTSRequestInfo>({
  selectId: (item) => item.id,
});

export const ttsRequestQueueAdapterSelectors =
  ttsRequestQueueAdapter.getSelectors(
    (state: RootState) => state.translationInfo.ttsRequestQueue,
  );

export const audioUrlQueueAdapter = createEntityAdapter<string>({
  selectId: (item) => item,
});

export const audioUrlQueueAdapterSelectors = audioUrlQueueAdapter.getSelectors(
  (state: RootState) => state.translationInfo.audioUrlQueue,
);

/**
 * 初期State
 */
const initialState: TranslationInfoState = {
  licenseToken: '',
  micStatus: MIC_STATUS.NONE,
  sttStatus: STT_STATUS.INACTIVE,
  ttsStatus: TTS_STATUS.INACTIVE,
  retryStatus: RETRY_STATUS.NONE,
  recognizedList: recognizedListAdapter.getInitialState(),
  sttErrorType: STT_ERROR_TYPE.NONE,
  ttsRequestQueue: ttsRequestQueueAdapter.getInitialState(),
  audioUrlQueue: audioUrlQueueAdapter.getInitialState(),
};

/**
 * 翻訳を行うにあたっての情報を一元管理 Slice
 */
export const translationInfoSlice = createSlice({
  name: 'translationInfo',
  initialState,
  reducers: {
    // ライセンストークンを更新
    setLicenseToken: (state, action: PayloadAction<string>) => {
      state.licenseToken = action.payload;
    },
    // マイク認識状況を更新
    setMicStatus: (state, action: PayloadAction<MicStatus>) => {
      state.micStatus = action.payload;
    },
    // 音声認識(収録)状況を更新
    setSttStatus: (state, action: PayloadAction<STTStatus>) => {
      state.sttStatus = action.payload;
    },
    // 音声合成(読み上げ)状況を更新
    setTtsStatus: (state, action: PayloadAction<TTSStatus>) => {
      state.ttsStatus = action.payload;
    },
    // リトライ状況を更新
    setRetryStatus: (state, action: PayloadAction<RetryStatus>) => {
      state.retryStatus = action.payload;
    },
    // 翻訳画面の表示内容(翻訳リスト)を更新
    setRecognizedList: (state, action: PayloadAction<RecognizedItem[]>) => {
      recognizedListAdapter.setAll(state.recognizedList, action.payload);

      return state;
    },
    // 翻訳画面の翻訳リストに翻訳結果を新規追加
    addNewRecognizedItem: (state, action: PayloadAction<RecognizedItem>) => {
      recognizedListAdapter.addOne(state.recognizedList, action.payload);

      return state;
    },
    // 失敗理由
    setSttErrorType: (state, action: PayloadAction<SttErrorType>) => {
      state.sttErrorType = action.payload;
    },
    // TTSリクエスト情報格納キューを更新
    setTtsRequestQueue: (state, action: PayloadAction<TTSRequestInfo[]>) => {
      ttsRequestQueueAdapter.setAll(state.ttsRequestQueue, action.payload);

      return state;
    },
    // TTSリクエスト情報格納キューにリクエスト情報を新規追加
    addNewTtsRequestQueue: (state, action: PayloadAction<TTSRequestInfo>) => {
      ttsRequestQueueAdapter.addOne(state.ttsRequestQueue, action.payload);

      return state;
    },
    // TTSの音声ファイルURL格納キューを更新
    setAudioUrlQueue: (state, action: PayloadAction<string[]>) => {
      audioUrlQueueAdapter.setAll(state.audioUrlQueue, action.payload);

      return state;
    },
    // TTSの音声ファイルURL格納キューにURLを新規追加
    addNewAudioUrlQueue: (state, action: PayloadAction<string>) => {
      audioUrlQueueAdapter.addOne(state.audioUrlQueue, action.payload);

      return state;
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
