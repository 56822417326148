import React from 'react';

import logo from '@/assets/logo.svg';

import { FontSizeContainer } from './FontSizeContainer';
import styles from './Header.module.scss';

/**
 * ヘッダー 子コンポーネント
 *
 * @returns
 */
const HeaderView = React.memo(() => (
    <header data-testid="header">
      <div className={styles['header-contents']}>
        <div className={styles['header-logo']}>
          <div>
            <img
              className={styles['header-logo-img']}
              src={logo}
              alt="Workflow"
              decoding="async"
            />
          </div>
        </div>
        <div className={styles['header-right-contents']}>
          {/* 文字サイズボタン(翻訳画面のみ表示) */}
          <FontSizeContainer />
        </div>
      </div>
    </header>
  ));

/**
 * ヘッダー コンポーネント
 *
 * @returns
 */
export const Header = React.memo(() => <HeaderView />);
