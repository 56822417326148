/**
 * TTSの音声設定
 */
export const TTS_AUDIO_CONFIG = {
  // エンコーディング
  AUDIO_ENCODING: 'MP3',
  // 発声速度
  SPEAKING_RATE: 1.5,
  // ピッチ
  PITCH: 0,
} as const;

/**
 * TTS発話情報の型
 */
type VoiceInfo = {
  [key: string]: {
    languageCode: string;
    voiceName: string;
  };
};

/**
 * TTS発話情報
 */
export const TTS_VOICE_INFO: VoiceInfo = {
  // アイスランド語
  is: {
    languageCode: 'is-IS',
    voiceName: 'is-IS-Standard-A',
  },
  // アフリカーンス語
  af: {
    languageCode: 'af-ZA',
    voiceName: 'af-ZA-Standard-A',
  },
  // アラビア語
  ar: {
    languageCode: 'ar-XA',
    voiceName: 'ar-XA-Standard-A',
  },
  // イタリア語
  it: {
    languageCode: 'it-IT',
    voiceName: 'it-IT-Neural2-A',
  },
  // インドネシア語
  id: {
    languageCode: 'id-ID',
    voiceName: 'id-ID-Standard-A',
  },
  // ウクライナ語
  uk: {
    languageCode: 'uk-UA',
    voiceName: 'uk-UA-Standard-A',
  },
  // 英語(米国)
  en: {
    languageCode: 'en-US',
    voiceName: 'en-US-Neural2-C',
  },
  // 英語(英国)
  'en-GB': {
    languageCode: 'en-GB',
    voiceName: 'en-GB-Neural2-A',
  },
  // 英語(インド)
  'en-IN': {
    languageCode: 'en-IN',
    voiceName: 'en-IN-Neural2-A',
  },
  // 英語(オーストラリア)
  'en-AU': {
    languageCode: 'en-AU',
    voiceName: 'en-AU-Neural2-A',
  },
  // オランダ語
  nl: {
    languageCode: 'nl-NL',
    voiceName: 'nl-NL-Standard-A',
  },
  // カタルーニャ語
  ca: {
    languageCode: 'ca-ES',
    voiceName: 'ca-ES-Standard-A',
  },
  // ガリシア語
  gl: {
    languageCode: 'gl-ES',
    voiceName: 'gl-ES-Standard-A',
  },
  // 韓国語
  ko: {
    languageCode: 'ko-KR',
    voiceName: 'ko-KR-Neural2-B',
  },
  // 広東語
  yue: {
    languageCode: 'yue-HK',
    voiceName: 'yue-HK-Standard-A',
  },
  // カンナダ語
  kn: {
    languageCode: 'kn-IN',
    voiceName: 'kn-IN-Standard-A',
  },
  // ギリシャ語
  el: {
    languageCode: 'el-GR',
    voiceName: 'el-GR-Standard-A',
  },
  // スウェーデン語
  sv: {
    languageCode: 'sv-SE',
    voiceName: 'sv-SE-Standard-A',
  },
  // スペイン語(スペイン)
  es: {
    languageCode: 'es-ES',
    voiceName: 'es-ES-Neural2-A',
  },
  // スペイン語(米国)
  'es-US': {
    languageCode: 'es-US',
    voiceName: 'es-US-Neural2-A',
  },
  // スロバキア語
  sk: {
    languageCode: 'sk-SK',
    voiceName: 'sk-SK-Standard-A',
  },
  // セルビア語
  sr: {
    languageCode: 'sr-RS',
    voiceName: 'sr-RS-Standard-A',
  },
  // タイ語
  th: {
    languageCode: 'th-TH',
    voiceName: 'th-TH-Neural2-C',
  },
  // タミル語
  ta: {
    languageCode: 'ta-IN',
    voiceName: 'ta-IN-Standard-A',
  },
  // チェコ語
  cs: {
    languageCode: 'cs-CZ',
    voiceName: 'cs-CZ-Standard-A',
  },
  // 中国語(簡体字)
  'zh-CN': {
    languageCode: 'cmn-CN',
    voiceName: 'cmn-CN-Wavenet-A',
  },
  // 中国語(繁体字)
  'zh-TW': {
    languageCode: 'cmn-TW',
    voiceName: 'cmn-TW-Wavenet-A',
  },
  // テルグ語
  te: {
    languageCode: 'te-IN',
    voiceName: 'te-IN-Standard-A',
  },
  // デンマーク語
  da: {
    languageCode: 'da-DK',
    voiceName: 'da-DK-Neural2-D',
  },
  // ドイツ語
  de: {
    languageCode: 'de-DE',
    voiceName: 'de-DE-Neural2-C',
  },
  // トルコ語
  tr: {
    languageCode: 'tr-TR',
    voiceName: 'tr-TR-Standard-A',
  },
  // 日本語
  ja: {
    languageCode: 'ja-JP',
    voiceName: 'ja-JP-Neural2-B',
  },
  // ノルウェー語
  no: {
    languageCode: 'nb-NO',
    voiceName: 'nb-NO-Standard-A',
  },
  // ハンガリー語
  hu: {
    languageCode: 'hu-HU',
    voiceName: 'hu-HU-Standard-A',
  },
  // パンジャーブ語
  pa: {
    languageCode: 'pa-IN',
    voiceName: 'pa-IN-Standard-A',
  },
  // ヒンディー語
  hi: {
    languageCode: 'hi-IN',
    voiceName: 'hi-IN-Neural2-A',
  },
  // フィリピノ語
  tl: {
    languageCode: 'fil-PH',
    voiceName: 'fil-ph-Neural2-A',
  },
  // フィンランド語
  fi: {
    languageCode: 'fi-FI',
    voiceName: 'fi-FI-Standard-A',
  },
  // フランス語(カナダ)
  'fr-CA': {
    languageCode: 'fr-CA',
    voiceName: 'fr-CA-Neural2-A',
  },
  // フランス語(フランス)
  fr: {
    languageCode: 'fr-FR',
    voiceName: 'fr-FR-Neural2-C',
  },
  // ブルガリア語
  bg: {
    languageCode: 'bg-BG',
    voiceName: 'bg-BG-Standard-A',
  },
  // ベトナム語
  vi: {
    languageCode: 'vi-VN',
    voiceName: 'vi-VN-Neural2-A',
  },
  // ヘブライ語
  iw: {
    languageCode: 'he-IL',
    voiceName: 'he-IL-Standard-A',
  },
  // ベンガル語
  bn: {
    languageCode: 'bn-IN',
    voiceName: 'bn-IN-Standard-A',
  },
  // ポーランド語
  pl: {
    languageCode: 'pl-PL',
    voiceName: 'pl-PL-Standard-A',
  },
  // ポルトガル語(ブラジル)
  'pt-BR': {
    languageCode: 'pt-BR',
    voiceName: 'pt-BR-Wavenet-A',
  },
  // ポルトガル語(ポルトガル)
  pt: {
    languageCode: 'pt-PT',
    voiceName: 'pt-PT-Wavenet-A',
  },
  // マラヤーラム語
  ml: {
    languageCode: 'ml-IN',
    voiceName: 'ml-IN-Standard-A',
  },
  // マレー語
  ms: {
    languageCode: 'ms-MY',
    voiceName: 'ms-MY-Standard-A',
  },
  // ラトビア語
  lv: {
    languageCode: 'lv-LV',
    voiceName: 'lv-LV-Standard-A',
  },
  // ルーマニア語
  ro: {
    languageCode: 'ro-RO',
    voiceName: 'ro-RO-Standard-A',
  },
  // ロシア語
  ru: {
    languageCode: 'ru-RU',
    voiceName: 'ru-RU-Wavenet-C',
  },
} as const;
export type TtsVoiceInfo = (typeof TTS_VOICE_INFO)[keyof typeof TTS_VOICE_INFO];
