import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { MainLayout } from '@/components/Layout';
import i18n from '@/config/i18n';
import { PAGE_ROUTE_PATH, URL_PARAMS_KEY } from '@/constants';
import { Agreement } from '@/features/agreement';
import { NotFound, AuthError } from '@/features/error';
import { Home } from '@/features/home';
import { ToastContainer } from '@/features/toast';
import { Speaker, Audience } from '@/features/translation';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import store from '@/states/store';

const App = () => {
  // UI言語を元に表示メッセージ言語切り替え
  const { language } = useBrowserUserSetting();
  i18n.changeLanguage(language);

  return (
    <>
      <Helmet>
        <title>{t('title.ポケトーク同時通訳')}</title>
      </Helmet>
      <MainLayout>
        <Outlet /> {/* ここの部分のみでコンポーネントが置き換わる */}
      </MainLayout>
    </>
  );
};

/**
 * 本システムで表示する画面定義
 *
 * @returns
 */
export const AppRoutes = () => (
  <BrowserRouter>
    <Provider store={store}>
        {/* トーストを表示(rootと同じ階層に表示することで画面遷移しても消えなくなる) */}
        <ToastContainer />
        <Routes>
          <Route element={<App />}>
            {/* ドメイン直打ち */}
            <Route path="/" element={<Home />} />
            {/* ホーム画面 */}
            <Route path={PAGE_ROUTE_PATH.HOME} element={<Home />} />
            {/* スピーカー画面 */}
            <Route
              path={`${PAGE_ROUTE_PATH.SESSION.SPEAKER}/:${URL_PARAMS_KEY.SPEAKER.token}`}
              element={<Speaker />}
            />
            {/* オーディエンス画面 */}
            <Route
              path={`${PAGE_ROUTE_PATH.SESSION.AUDIENCE}/:${URL_PARAMS_KEY.AUDIENCE.token}`}
              element={<Audience />}
            />
            {/* 利用規約画面 */}
            <Route path={PAGE_ROUTE_PATH.AGREEMENT} element={<Agreement />} />
            {/* 認証エラー画面 */}
            <Route path={PAGE_ROUTE_PATH.AUTH_ERROR} element={<AuthError />} />
            {/* 404エラー画面 */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
    </Provider>
  </BrowserRouter>
);
