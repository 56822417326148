import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

import { useAccessExpired } from '../hooks/useAccessExpired';

type AccessExpiredDialog = {
  isOpen: boolean;
};

/**
 * アクセス(トークン)期限切れダイアログ コンポーネント
 *
 * @param param0 本ダイアログ表示/非表示
 * @returns
 */
export const AccessExpiredDialog = React.memo(
  ({ isOpen }: AccessExpiredDialog) => {
    const { clickAccessExpiredOKButton } = useAccessExpired();
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        title={t(
          'accessExpiredDialog.ログインの有効期限が切れました',
        ).toString()}
        buttons={[{ label: 'OK', onClick: clickAccessExpiredOKButton }]}
      >
        <DialogMessageContents>
          {t(
            'accessExpiredDialog.一定時間操作が無かったため、ログアウトしました。再度ログインしてください。',
          )}
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
