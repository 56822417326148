import React, { useRef } from 'react';

import menu from '@/assets/menu.svg';
import {
  POPUP_CONTENT_ALIGN_POSITION,
  PopupContents,
  PopupDetails,
  PopupSummary,
} from '@/components/Elements';
import { useKeyDown } from '@/hooks/useKeyDown';

import styles from '../TranslationFooter.module.scss';

import { DisplayMenuContainer } from './DisplayMenuContainer';

/**
 * オーディエンス画面のメニュー
 */
export const AudienceMenuContainer = React.memo(() => {
  // メニュークリックでopen要素削除する
  const detailsRef = useRef<HTMLDetailsElement | null>(null);

  /**
   * クローズ処理
   */
  const onClose = (): void => {
    detailsRef.current?.removeAttribute('open');
  };
  // Escキーが押下されたらメニューを閉じる
  useKeyDown({ onEscapeKeyDown: onClose });

  return (
    <div>
      <PopupDetails detailsRef={detailsRef}>
        <PopupSummary>
          <div className={styles.menu}>
            <img src={menu} alt="menu" />
          </div>
        </PopupSummary>
        <PopupContents align={POPUP_CONTENT_ALIGN_POSITION.RIGHT}>
          <div className={styles['menu-contents']}>
            <ul>
              <li>
                {/* 翻訳元テキストの表示/非表示切り替えメニュー(翻訳画面でのみ表示) */}
                <DisplayMenuContainer
                  onCloseMenu={() => {
                    onClose();
                  }}
                />
              </li>
            </ul>
          </div>
        </PopupContents>
      </PopupDetails>
    </div>
  );
});
