import React from 'react';
import { useParams } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { FONT_SIZE_INFO, URL_PARAMS_KEY } from '@/constants';
import { Header } from '@/features/header';
import { useTranslationDisplay } from '@/hooks/useTranslationDisplay';

import { useAudience } from '../../hooks/audience/useAudience';
import { AudienceFooter } from '../footer/AudienceFooter';
import { AudienceListView, AudienceOffairView } from '../list/AudienceListView';

/**
 * オーディエンス画面 コンポーネント
 *
 * @returns
 */
export const Audience = React.memo(() => {
  const { token: tokenParam } = useParams<{
    [URL_PARAMS_KEY.AUDIENCE.token]: string;
  }>();
  const { interimText, recognizedTexts, isOnair } = useAudience({
    token: tokenParam || '',
  });
  const { fontSize, displayType } = useTranslationDisplay();

  return (
    <>
      <Header />
      <div>
        {isOnair ? (
          <ContentLayout footerChildren={<AudienceFooter />}>
            <AudienceListView
              interimText={interimText}
              recognizedList={recognizedTexts}
              displayType={displayType}
              fontSize={fontSize}
              point={FONT_SIZE_INFO.point}
            />
          </ContentLayout>
        ) : (
          <AudienceOffairView />
        )}
      </div>
    </>
  );
});
