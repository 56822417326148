import React from 'react';
import { useTranslation } from 'react-i18next';

import { ALIGN_TYPE, TextBlock } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';

import styles from './NotFound.module.scss';

/**
 * NotFound（404エラー）画面
 *
 * @returns
 */
export const NotFound = React.memo(() => {
  const { t } = useTranslation();

  return (
    <ContentLayout>
      <div className={styles['not-found']}>
        <TextBlock
          text={t('notFound.ページが見つかりませんでした')}
          align={ALIGN_TYPE.center}
        />
        <TextBlock text="(PAGE NOT FOUND)" align={ALIGN_TYPE.center} />
      </div>
    </ContentLayout>
  );
});
