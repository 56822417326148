import { useCallback, useEffect, useMemo, useState } from 'react';

import { PullDownMenuItem } from '@/components/Elements';
import { VOICE_TYPE, langList, LangData } from '@/features/api';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useLanguage } from '@/hooks/useLanguage';

/**
 * 本カスタムフックからの返却値
 */
export type Value = {
  // STT言語リスト
  sttLangList: PullDownMenuItem[];
  // TTS言語リスト
  ttsLangList: PullDownMenuItem[];
  // 翻訳元言語が変更された場合の処理
  onChangeSrclang: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  // 翻訳先言語が変更された場合の処理
  onChangeDestlang: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

/**
 * 翻訳先言語/翻訳元言語 ドロップダウンメニュー hooks
 *
 * @returns
 */
export const useLanguageMenu = (): Value => {
  const { srclang, destlang, setSrclang, setDestlang } = useLanguage();

  // STT言語一覧
  const [sttLangList, setSttLangList] = useState<PullDownMenuItem[]>([]);
  // TTS言語一覧
  const [ttsLangList, setTtsLangList] = useState<PullDownMenuItem[]>([]);

  // 翻訳元言語のデフォルト
  const defaultSttLanguage = useMemo(() => 'en', []);
  // 翻訳先言語のデフォルト
  const defaultTttLanguage = useMemo(() => 'ja', []);

  const { language } = useBrowserUserSetting();

  /**
   * 翻訳元言語が変更された場合の処理
   */
  const onChangeSrclang = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // 選択された翻訳元言語をReduxに保存
      const { value } = e.currentTarget;
      setSrclang(value);
    },
    [setSrclang],
  );

  /**
   * 翻訳先言語が変更された場合の処理
   */
  const onChangeDestlang = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // 選択された翻訳元言語をReduxに保存
      const { value } = e.currentTarget;
      setDestlang(value);
    },
    [setDestlang],
  );

  /**
   * 取得した言語一覧をプルダウンメニュー形式に変換
   */
  const convertPullDownMenuItem = useCallback(
    (dataArray: LangData[]): PullDownMenuItem[] => {
      const menuItemArray: PullDownMenuItem[] = [];
      dataArray.forEach((element) => {
        Object.keys(element).forEach((key) => {
          menuItemArray.push({ label: element[key], value: key });
        });
      });

      return menuItemArray;
    },
    [],
  );

  /**
   * STT言語一覧取得
   */
  const fetchSttLangList = useCallback(() => {
    langList({
      voiceType: VOICE_TYPE.STT,
      uiLanguage: language,
    }).then((response) => {
      const menuItemArray = convertPullDownMenuItem(response);
      setSttLangList(menuItemArray);
    });
  }, [convertPullDownMenuItem, language]);

  /**
   * TTS言語一覧取得
   */
  const fetchTtsLangList = useCallback(() => {
    langList({
      voiceType: VOICE_TYPE.TTS,
      uiLanguage: language,
    }).then((response) => {
      const menuItemArray = convertPullDownMenuItem(response);
      setTtsLangList(menuItemArray);
    });
  }, [convertPullDownMenuItem, language]);

  /**
   * マウント時の処理
   */
  useEffect(() => {
    // Reduxに翻訳元言語が保存されてない場合は「英語(デフォルト)」を設定
    if (!srclang) {
      setSrclang(defaultSttLanguage);
    }
    // Reduxに翻訳先言語が保存されてない場合は「日本語(デフォルト)」を設定
    if (!destlang) {
      setDestlang(defaultTttLanguage);
    }

    // STT言語一覧取得
    fetchSttLangList();
    // TTS言語一覧取得
    fetchTtsLangList();

    // 画面表示処理のため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * UI言語が変更された時の処理
   */
  useEffect(() => {
    // UI言語が変更された時、再取得して表示言語を切り替える
    fetchSttLangList();
    fetchTtsLangList();
  }, [fetchSttLangList, fetchTtsLangList, language]);

  return {
    sttLangList,
    ttsLangList,
    onChangeSrclang,
    onChangeDestlang,
  };
};
