import { useCallback, useEffect, useRef } from 'react';

export const useMainLayout = () => {
  const mainLayoutRef = useRef<HTMLElement>(null);

  /**
   * 画面サイズによる動的な高さの制御
   */
  const updateLayoutHeightToWindowHeightAfterDelay = useCallback(() => {
    // スマホの回転時に、回転前の値が取得されるので、少し遅らせてから設定する。
    setTimeout(() => {
      if (!mainLayoutRef.current) {
        return;
      }

      const vh = window.innerHeight;

      mainLayoutRef.current.style.height = `${vh}px`;
    }, 100);
  }, []);

  // 画面初期表示時に必ず動作するように追加
  useEffect(() => {
    updateLayoutHeightToWindowHeightAfterDelay();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener(
      'resize',
      updateLayoutHeightToWindowHeightAfterDelay,
    );

    return () => {
      window.addEventListener(
        'resize',
        updateLayoutHeightToWindowHeightAfterDelay,
      );
    };
  }, [updateLayoutHeightToWindowHeightAfterDelay]);

  return {
    mainLayoutRef,
  };
};
