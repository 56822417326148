import {
  query,
  collection,
  doc,
  onSnapshot,
  where,
  orderBy,
  limit,
} from 'firebase/firestore';
import { useCallback, useRef, useState } from 'react';

import {
  RecognizedText,
  RecognizedTextData,
  InterimTextData,
  FIRESTORE_PATH,
  FirestoreSessionInfo,
} from '@/constants';
import { useLanguage } from '@/hooks/useLanguage';
import { firestore } from '@/utils/firebases/firebaseFirestore';

export const useFirestore = () => {
  const { destlang } = useLanguage();
  const [recognizedTextData, setRecognizedTextData] = useState<
    RecognizedText[]
  >([]);
  const [interimTextData, setInterimTextData] = useState<string>('');

  // RecognizedTextに設定するidのRef
  const recognizedTextIdRef = useRef<number>(0);

  const [isOnair, setIsOnair] = useState<boolean>(false);

  /**
   * 現在の配信状態を取得する
   */
  const subscribeOnairTime = useCallback((fireStoreId: string) => {
    onSnapshot(
      doc(firestore, FIRESTORE_PATH.SESSIONS, fireStoreId),
      (docSnap) => {
        const firestoreSessionInfo: FirestoreSessionInfo = JSON.parse(
          JSON.stringify(docSnap.data()),
        );
        setIsOnair(
          firestoreSessionInfo.onair_time !== undefined &&
            firestoreSessionInfo.onair_time !== null,
        );
      },
    );
  }, []);

  const subscribeRecognizedTextData = useCallback(
    (fireStoreId: string) => {
      const docRef = doc(
        firestore,
        FIRESTORE_PATH.SESSIONS,
        fireStoreId,
        FIRESTORE_PATH.RECOGNIZED_TEXTS,
        FIRESTORE_PATH.LANGUAGES,
      );

      // 翻訳先言語の言語コードを設定する想定。
      const queryRecognizedTexts = query(
        collection(docRef, destlang),
        // 入室した瞬間以降を取得する
        where('timestamp', '>', new Date()),
        // 最新が一番下
        orderBy('timestamp', 'asc'),
      );

      return onSnapshot(queryRecognizedTexts, async (textSnapshot) => {
        const datas: RecognizedText[] = [];

        await Promise.all(
          textSnapshot.docChanges().map(async (change) => {
            if (change.type !== 'added') {
              return;
            }
            const data = JSON.stringify(change.doc.data());
            const parseData: RecognizedTextData = JSON.parse(data);
            datas.push({ id: recognizedTextIdRef.current, value: parseData });
          }),
        );
        setRecognizedTextData((current) => [...current, ...datas]);
        recognizedTextIdRef.current += 1;

        // 確定テキストが入ったタイミングで暫定テキストを削除
        setInterimTextData('');
      });
    },
    [destlang],
  );

  /**
   * 暫定テキストをfirestoreから取得
   */
  const subscribeInterimTextData = useCallback((fireStoreId: string) => {
    const docRef = doc(firestore, FIRESTORE_PATH.SESSIONS, fireStoreId);

    const queryInterimTexts = query(
      collection(docRef, FIRESTORE_PATH.INTERIM_TEXTS),
      // 最新の1件のみ取得する
      orderBy('timestamp', 'desc'),
      limit(1),
    );

    onSnapshot(queryInterimTexts, async (textSnapshot) => {
      await Promise.all(
        textSnapshot.docs.map(async (text) => {
          const data = JSON.stringify(text.data());
          const parseData: InterimTextData = JSON.parse(data);
          setInterimTextData(parseData.stt);
        }),
      );
    });
  }, []);

  /**
   * 暫定テキストと確定テキストの監視を開始
   */
  const subcscribeTextData = useCallback(
    (fireStoreId: string) => {
      subscribeOnairTime(fireStoreId);
      subscribeInterimTextData(fireStoreId);
      if (!destlang) {
        return undefined;
      }

      return subscribeRecognizedTextData(fireStoreId);
    },
    [
      destlang,
      subscribeInterimTextData,
      subscribeOnairTime,
      subscribeRecognizedTextData,
    ],
  );

  return {
    subcscribeTextData,
    recognizedTextData,
    interimTextData,
    isOnair,
  };
};
