import { useCallback } from 'react';

import { LIST_DISPLAY_TYPE } from '@/constants';
import { useTranslationDisplay } from '@/hooks/useTranslationDisplay';

/**
 * 本カスタムフックからの返却値
 */
export type Value = {
  // 表示切り替えボタンがクリックされた場合の処理
  onClickButton: () => void;
};

/**
 * 翻訳元テキストの表示/非表示 切り替えボタン hooks
 * @returns
 */
export const useDisplayTypeButton = (): Value => {
  const { displayType, setDisplayType } = useTranslationDisplay();

  /**
   * 表示切り替えボタンがクリックされた場合の処理
   */
  const onClickButton = useCallback(() => {
    if (displayType === LIST_DISPLAY_TYPE.onePhrase) {
      setDisplayType(LIST_DISPLAY_TYPE.list);

      return;
    }
    setDisplayType(LIST_DISPLAY_TYPE.onePhrase);
  }, [displayType, setDisplayType]);

  return {
    onClickButton,
  };
};
