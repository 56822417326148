import React from 'react';

import {
  UnSupportBrowserDialog,
  useUnSupportBrowserDialog,
} from '@/features/unspport';

/**
 * 非対応ブラウザ警告ダイアログ
 */
export const UnSupportBrowserDialogContainer = React.memo(() => {
  const { isUnSupportBrowserDialogOpen, clickUnSupportBrowserOKButton } =
    useUnSupportBrowserDialog();

  return (
    <UnSupportBrowserDialog
      isOpen={isUnSupportBrowserDialogOpen}
      onClose={clickUnSupportBrowserOKButton}
    />
  );
});
