import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import deDe from './de-de.json';
import enUs from './en-us.json';
import esEs from './es-es.json';
import frFr from './fr-fr.json';
import itIt from './it-it.json';
import jaJp from './ja-jp.json';
import koKr from './ko-kr.json';
import msMy from './ms-my.json';
import ptPt from './pt-pt.json';
import ruRu from './ru-ru.json';
import thTh from './th-th.json';
import zhCn from './zh-cn.json';
import zhTw from './zh-tw.json';

const resources = {
  ja: { translation: jaJp },
  en: { translation: enUs },
  zh: { translation: zhCn },
  'zh-CN': { translation: zhCn },
  'zh-TW': { translation: zhTw },
  es: { translation: esEs },
  pt: { translation: ptPt },
  ru: { translation: ruRu },
  fr: { translation: frFr },
  de: { translation: deDe },
  ko: { translation: koKr },
  it: { translation: itIt },
  th: { translation: thTh },
  ms: { translation: msMy },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    detection: {
      order: ['querystring', 'navigator', 'htmlTag'],
      lookupQuerystring: 'locale',
      htmlTag: document.documentElement,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
