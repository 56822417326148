import { PortalDocumentBody } from '../../Portal/PortalDocumentBody';
import { Spinner } from '../Spinner/Spinner';

import styles from './SpinnerOverlayView.module.scss';

/**
 * プロパティ
 */
export type SpinnerOverlayView = {
  // 表示するか否か(true=表示|false=非表示)
  isOpen: boolean;
};

/**
 * スピナーOverlayビュー コンポーネント
 *
 * @param param0
 * @returns
 */
export const SpinnerOverlayView = ({ isOpen = false }: SpinnerOverlayView) => {
  if (!isOpen) {
    return null;
  }

  return (
    <PortalDocumentBody>
      <div className={styles['spinner-outer']}>
        <Spinner />
      </div>
    </PortalDocumentBody>
  );
};
