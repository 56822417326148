import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

import { useOtherError } from '../../hooks/error/useOtherError';

type OtherErrorDialog = {
  isOpen: boolean;
};

/**
 * その他エラーダイアログ コンポーネント
 *
 * @param param0 本ダイアログ表示/非表示
 * @returns
 */
export const OtherErrorDialog = React.memo(({ isOpen }: OtherErrorDialog) => {
  const { clickOtherErrorOKButton } = useOtherError();
  const { t } = useTranslation();

  return (
    <ModalDialog
      isOpen={isOpen}
      title={t(
        'translationDialog.サーバー混雑等の理由で現在通訳機能が利用できません',
      ).toString()}
      buttons={[{ label: 'OK', onClick: clickOtherErrorOKButton }]}
    >
      <DialogMessageContents>
        {t('translationDialog.しばらく待ってから再度ご利用ください。')}
      </DialogMessageContents>
    </ModalDialog>
  );
});
