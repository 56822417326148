import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * State
 */
export type AudioInfoState = {
  // Audioが再生中か否か
  isAudioPlay: boolean;
};

/**
 * 初期State
 */
const initialState: AudioInfoState = {
  isAudioPlay: false,
};

/**
 * Audio情報管理 Slice
 */
export const audioInfoSlice = createSlice({
  name: 'audioInfo',
  initialState,
  reducers: {
    // Audio再生状況を更新
    setIsAudioPlay: (state, action: PayloadAction<boolean>) => {
      state.isAudioPlay = action.payload;
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
