import { CONFERENCE } from '@/constants';
import { conferenceAxios } from '@/lib/axios';

import { API_AUTH_RESULT_CODE } from './apiAuth';

/**
 * リクエストパラメータ
 */
export type SessionInfoApiRequest = {
  // トークン
  token: string;
};

/**
 * 結果コード定数
 */
export const SESSION_INFO_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // セッション情報が見つからない
  WARN_NO_SESSION: 'WARN_NO_SESSION',
  // 不明エラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',

  ...API_AUTH_RESULT_CODE,
} as const;
export type SessionInfoResultCode =
  (typeof SESSION_INFO_API_RESULT_CODE)[keyof typeof SESSION_INFO_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isSessionInfoApiResultCode = (
  resultCode: string,
): resultCode is SessionInfoResultCode => {
  if (resultCode in SESSION_INFO_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type SessionInfoApiResponse = {
  // 結果コード
  resultCode: SessionInfoResultCode;
  // セッション名
  name: string;
  // セッション開始日（yyyy-MM-dd'T'HH:mm:ssXXX）
  startdt: string;
  // セッション終了日（yyyy-MM-dd'T'HH:mm:ssXXX）
  enddt: string;
  // firetstoreのセッション情報のドキュメントID
  firestoreSessionId: string;
  // ユーザー登録済みか否か
  isRegistered: boolean;
  // オーディエンス画面のURL
  audienceUrl: string;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (
  request: SessionInfoApiRequest,
): Promise<SessionInfoApiResponse> => {
  // リクエスト送信(POST)
  const response = conferenceAxios
    .post<SessionInfoApiResponse>(CONFERENCE.URL.SESSION_INFO, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * セッション情報取得APIリクエスト
 *
 * @returns
 */
export const sessionInfoApi = (
  request: SessionInfoApiRequest,
): Promise<SessionInfoApiResponse> => callApi(request);
