/**
 * 利用許諾画面のエラー状態
 */
export const AGREEMENT_STATUS = {
  // 何もしていない
  NONE: 'NONE',
  // 利用許諾APIエラー
  AGREEMENT_ERROR: 'AGREEMENT_ERROR',
  // 成功
  SUCCESS: 'SUCCESS',
};
export type AgreementStatusType =
  (typeof AGREEMENT_STATUS)[keyof typeof AGREEMENT_STATUS];

/**
 * エラーメッセージ
 */
export const AgreementErrorMessageMap: {
  [key in AgreementStatusType]: string;
} = {
  [AGREEMENT_STATUS.NONE]: '',
  [AGREEMENT_STATUS.AGREEMENT_ERROR]:
    '不明なエラーが発生しました。しばらく待ってから再度「利用を開始する」ボタンを押してください。',
  [AGREEMENT_STATUS.SUCCESS]: '',
};

/**
 * 利用規約画面への遷移時に渡す値
 */
export type AgreementState = {
  path: string;
};
