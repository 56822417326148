// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';

import styles from '../../../features/header/components/Header.module.scss';

/**
 * プロパティ
 */
export type InputSlider = {
  // 最小値
  min?: number;
  // 最大値
  max?: number;
  // ステップ
  step?: number;
  // デフォルト値
  defaultValue?: number;
  // 無効化(default=false)
  disabled?: boolean;
  // クリック時の処理
  onClick?: () => void;
  // 変更時の処理
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * inputスライダー コンポーネント
 */
export const InputSlider = React.memo(
  ({
    min = 0,
    max = 100,
    step = 1,
    defaultValue = 100,
    disabled = false,
    onClick,
    onChange,
  }: InputSlider) => (
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      data-testid="input-slider"
      defaultValue={defaultValue}
      disabled={disabled}
      onClick={onClick}
      onChange={onChange}
      className={styles['input-range']}
    />
  ),
);
