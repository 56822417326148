import { useCallback, useEffect, useRef } from 'react';

import { API_STATUS } from '@/constants';
import { useTtsApi } from '@/features/api';
import { useAudioInfo } from '@/hooks/useAudioInfo';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import {
  RETRY_STATUS,
  TTSStatus,
  TTS_STATUS,
} from '@/states/slices/translationInfoSlice';
import { convertToWavFileFromGoogleTts } from '@/utils';

import { TTS_AUDIO_CONFIG, TTS_VOICE_INFO } from '../constants';

import { useAudio } from './useAudio';

/**
 * 音声読み上げ(TTS) カスタムフック
 *
 * @returns
 */
export const useTextToSpeech = () => {
  const {
    ttsStatus,
    hasTtsRequest,
    addTtsRequest,
    shiftTtsRequestQueue,
    clearTtsRequestQueue,
    hasAudioUrl,
    addNewAudioUrlQueue,
    shiftAudioUrlQueue,
    clearAudioUrlQueue,
    setRetryStatus,
  } = useTranslationInfo();
  const { isAudioPlay } = useAudioInfo();
  const { play, pause, releaseAudioSource } = useAudio();
  const { fetchTts, ttsState } = useTtsApi();

  /**
   * 翻訳一覧を変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const ttsStatusRef = useRef<TTSStatus>(ttsStatus);
  useEffect(() => {
    ttsStatusRef.current = ttsStatus;
  }, [ttsStatus]);

  /**
   * TTSが停止された場合の処理
   */
  useEffect(() => {
    // 音声停止
    if (ttsStatus === TTS_STATUS.PAUSED) {
      // 停止されたらキューの中身を全て破棄(次回は最新のTTSから再生)
      clearTtsRequestQueue();
      clearAudioUrlQueue();
      // 停止
      pause();
      // 音声破棄
      releaseAudioSource();
    }
  }, [
    clearAudioUrlQueue,
    clearTtsRequestQueue,
    pause,
    releaseAudioSource,
    ttsStatus,
  ]);

  /**
   * 読み上げリストに追加(TTSするためのリクエスト情報をキューに格納)
   */
  const addTtsList = useCallback(
    (newTtt: string, language: string) => {
      if (ttsStatusRef.current !== TTS_STATUS.READING) {
        // 読み上げ中以外は格納しない
        return;
      }

      if (!Object.hasOwn(TTS_VOICE_INFO, language)) {
        // 非対応の言語の場合、格納しない
        return;
      }

      // TTSリクエスト情報をキューに格納
      addTtsRequest({
        input: { text: newTtt },
        voice: {
          languageCode: TTS_VOICE_INFO[language].languageCode,
          name: TTS_VOICE_INFO[language].voiceName,
        },
        audioConfig: {
          audioEncoding: TTS_AUDIO_CONFIG.AUDIO_ENCODING,
          speakingRate: TTS_AUDIO_CONFIG.SPEAKING_RATE,
          pitch: TTS_AUDIO_CONFIG.PITCH,
        },
      });
    },

    [addTtsRequest],
  );

  /**
   * TTSリクエスト情報キューを監視
   *
   * ・キューにTTSリクエスト情報が追加されたことを検知してTTS API呼び出しを実施
   * ・キュー内のすべてのデータのTTSが終わったら終了
   */
  useEffect(() => {
    if (ttsStatusRef.current !== TTS_STATUS.READING) {
      // 「読み上げ中」以外は何もしない
      return;
    }

    if (!hasTtsRequest) {
      return; // キューにデータが格納されていない場合は何もしない
    }

    if (ttsState.status === API_STATUS.LOADING) {
      return; // TTS API呼び出し中の場合は何もしない
    }

    // キューから最新のTTSリクエスト情報を取り出す
    const ttsRequestInfo = shiftTtsRequestQueue();
    if (ttsRequestInfo) {
      const requestData = ttsRequestInfo.ttsRequest;

      // TTS API呼び出し
      fetchTts({
        input: {
          text: requestData.input.text,
        },
        voice: {
          languageCode: requestData.voice.languageCode,
          name: requestData.voice.name,
        },
        audioConfig: {
          audioEncoding: requestData.audioConfig.audioEncoding,
          speakingRate: requestData.audioConfig.speakingRate,
          pitch: requestData.audioConfig.pitch,
        },
      });
    }
  }, [
    fetchTts,
    hasTtsRequest,
    setRetryStatus,
    shiftTtsRequestQueue,
    ttsState.status,
  ]);

  /**
   * TTSリクエスト結果を監視
   *
   * ・音声が順次再生されるように、TTSが成功したら音声ファイルURLキューに格納する
   */
  useEffect(() => {
    async function addAudioQueue() {
      if (ttsStatusRef.current !== TTS_STATUS.READING) {
        return; // 「読み上げ中」以外は何もしない
      }

      if (ttsState.status === API_STATUS.LOADING) {
        return; // TTS API呼び出し中の場合は何もしない
      }

      if (ttsState.status === API_STATUS.FAILED) {
        // TTS失敗時はリトライ実行
        setRetryStatus(RETRY_STATUS.RETRY);

        return;
      }

      if (ttsState.status === API_STATUS.SUCCESS && ttsState.data) {
        if (!ttsState.data.audioContent) {
          return;
        }

        const blobUrl = await convertToWavFileFromGoogleTts(
          ttsState.data.audioContent,
        );

        if (blobUrl) {
          addNewAudioUrlQueue(blobUrl);
        }
      }
    }
    addAudioQueue();
  }, [addNewAudioUrlQueue, setRetryStatus, ttsState.data, ttsState.status]);

  /**
   * 音声ファイルURLキューと音声再生状態を監視
   *
   * ・Audio再生が終わったことを検知して、キューから取り出した音声を再生
   * ・キュー内のすべてのデータの音声再生が終わったら終了
   * ・キューに音声ファイルURLが追加されたことを検知して音声再生を再開
   */
  useEffect(() => {
    if (ttsStatusRef.current !== TTS_STATUS.READING) {
      return; // 「読み上げ中」以外は何もしない
    }

    if (isAudioPlay) {
      return; // Audio再生中は何もしない
    }

    if (!hasAudioUrl) {
      return;
    }

    // 音声再生
    const audioUrl = shiftAudioUrlQueue();
    if (audioUrl) {
      play(audioUrl);
    }
  }, [hasAudioUrl, isAudioPlay, play, shiftAudioUrlQueue]);

  return {
    addTtsList,
  };
};
