/* eslint-disable import/prefer-default-export */
import ReactDOM from 'react-dom';

/**
 * プロパティ
 */
export type PortalDocumentBody = {
  // Portalでbodyの直下に表示したい子要素
  children: React.ReactNode;
};

/**
 * 引数で受け取った子要素をPortalでbodyの直下に表示する
 * https://ja.reactjs.org/docs/portals.html
 *
 * @param param children React の子要素としてレンダー可能なもの(要素、文字列、フラグメント)
 * @returns 引数で受け取った子要素をPortalでbodyの直下に表示する
 */
export const PortalDocumentBody = ({ children }: PortalDocumentBody) =>
  ReactDOM.createPortal(children, document.body);
