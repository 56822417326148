import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTE_PATH } from '@/constants';

/**
 * PTID期限切れ カスタムフック
 */
export const usePtidExpired = () => {
  const navigate = useNavigate();

  /**
   * PTID期限切れ
   */
  const ptidExpired = useCallback(() => {
    // ホーム画面に遷移させておく。【TODO】α2以降で対応
    navigate({
      pathname: PAGE_ROUTE_PATH.HOME,
    });
  }, [navigate]);

  return {
    ptidExpired,
  };
};
