import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * State
 */
export type LanguageState = {
  // 翻訳元言語
  srclang: string;
  // 翻訳先言語
  destlang: string;
};

/**
 * 初期化State
 */
const initialState: LanguageState = {
  srclang: '',
  destlang: '',
};

/**
 * 翻訳元言語/翻訳先言語 管理 Slice
 */
export const languageSlice = createSlice({
  name: 'browserLanguage',
  initialState,
  reducers: {
    setSrclang: (state, action: PayloadAction<string>) => {
      state.srclang = action.payload;
    },
    setDestlang: (state, action: PayloadAction<string>) => {
      state.destlang = action.payload;
    },
    clearLanguage: () => initialState,
  },
});
