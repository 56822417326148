import { Timestamp } from 'firebase/firestore';

/**
 * 表示タイプ
 */
export const LIST_DISPLAY_TYPE = {
  // 縦表示
  onePhrase: 'onePhrase',
  // 横表示
  list: 'list',
} as const;

export type ListDisplayType =
  (typeof LIST_DISPLAY_TYPE)[keyof typeof LIST_DISPLAY_TYPE];

/**
 * フォントサイズ変更情報
 */
export const FONT_SIZE_INFO = {
  minLength: 1,
  maxLength: 10,
  step: 0.25,
  defaultValue: 1.8,
  point: 'rem',
} as const;

/**
 * 翻訳画面：表示内容
 */
export type RecognizedContent = {
  // 音声認識結果テキスト
  stt: string;
  // 翻訳結果テキスト
  ttt: string;
  // 音声認識言語
  srclang: string;
  // 翻訳言語
  destlang: string;
  // 翻訳日時
  date: string;
};

/**
 * 翻訳画面：音声認識リストの1行分の型
 */
export type RecognizedItem = {
  // ユニークに識別するID(index番号)
  id: number;
  // 表示内容
  value: RecognizedContent;
};

/**
 * 翻訳画面：音声認識リストViewの型
 */
export type RecognizedList = {
  // 翻訳前/翻訳後テキストリストの表示内容
  lists: (RecognizedItem | RecognizedText)[];
  // 表示タイプ
  displayType: ListDisplayType;
};

/**
 * firestoreに保存されている確定テキストデータ
 */
export type RecognizedTextData = {
  // 音声認識結果テキスト
  stt: string;
  // 翻訳結果テキスト
  ttt: string;
  // 音声認識言語
  srclang: string;
  // 翻訳言語
  destlang: string;
  // ドキュメント作成日時
  timestamp: Timestamp;
};

/**
 * firestoreに保存されている暫定テキストデータ
 */
export type InterimTextData = {
  // 音声認識結果暫定テキスト
  stt: string;
  // 音声認識言語
  srclang: string;
  // ドキュメント作成日時
  timestamp: Timestamp;
};

/**
 * オーディエンス画面：音声認識リストの1行分の型
 */
export type RecognizedText = {
  // ユニークに識別するID(index番号)
  id: number;
  // 表示内容
  value: RecognizedTextData;
};

/**
 * firestoreのセッション情報
 */
export type FirestoreSessionInfo = {
  // 音声認識言語
  srclang: string;
  // 配信中にした日時（配信停止中はNULL）
  onair_time: Timestamp;
  // ドキュメント作成日時
  timestamp: Timestamp;
};
/**
 * コーデック情報
 */
export type CodecInfo = {
  // ファイル形式
  format: string;
  // サンプリングレート
  sampleRate: number;
  // ビット深度
  bitDepth: number;
};
