import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  PAGE_ROUTE_PATH,
} from '@/constants';

/**
 * その他エラー カスタムフック
 */
export const useOtherError = () => {
  const navigate = useNavigate();

  /**
   * その他エラーダイアログ用OKボタンのクリック処理
   */
  const clickOtherErrorOKButton = useCallback(() => {
    // 仕様を決めていないので一旦ホーム画面に遷移するようにしておく。
    navigate({
      pathname: PAGE_ROUTE_PATH.HOME,
    });
  }, [navigate]);

  return {
    clickOtherErrorOKButton,
  };
};
