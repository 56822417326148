import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PullDownMenuItem } from '@/components/Elements';
import { DEFAULT_DEVICE, DEFAULT_UI_LANG, UILanguageList } from '@/constants';
import {
  browserUserSettingSlice,
  BrowserUserSettingState,
} from '@/states/slices/browserUserSettingSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * デバイスやUI言語をReduxに保存 hooks
 *
 * @returns
 */
export const useBrowserUserSetting = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setInputDevice, setLanguage, clearUserSetting } =
    browserUserSettingSlice.actions;

  const { inputDevice, language } = useSelector<
    RootState,
    BrowserUserSettingState
  >((state) => state.browserUserSetting);

  /**
   * 入力元デバイス
   */
  const userSettingInputDevice = useMemo(() => {
    if (inputDevice) {
      return inputDevice;
    }

    return DEFAULT_DEVICE;
  }, [inputDevice]);

  /**
   * UI言語リストに対象の言語が含まれているかどうか
   *
   * @param lang
   * @returns true:含まれている、false:含まれていない
   */
  const isIncludedUILanguage = (lang: string): boolean => {
    const targetItem = UILanguageList.find(
      (item: PullDownMenuItem) => item.value === lang,
    );

    if (targetItem) {
      return true;
    }

    return false;
  };

  /**
   * UI言語
   */
  const userSettingLanguage = useMemo(() => {
    if (language) {
      return language;
    }

    // ブラウザ言語を取得
    const browserLang = navigator.language;

    if (browserLang) {
      if (isIncludedUILanguage(browserLang)) {
        return browserLang;
      }

      // 'en-US'などの表記になっている場合があるので'-'前の文字列で比較
      const preBrowserLang = browserLang.slice(0, browserLang.indexOf('-'));

      if (isIncludedUILanguage(preBrowserLang)) {
        return preBrowserLang;
      }
    }

    return DEFAULT_UI_LANG;
  }, [language]);

  /**
   * 入力元デバイスを更新
   */
  const changeInputDevice = useCallback(
    (value: string) => {
      dispatch(setInputDevice(value));
    },
    [dispatch, setInputDevice],
  );

  /**
   * UI言語を更新
   */
  const changeLanguage = useCallback(
    (value: string) => {
      dispatch(setLanguage(value));
    },
    [dispatch, setLanguage],
  );

  /**
   * [設定ダイアログ]の設定内容に関する全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(clearUserSetting());
  }, [clearUserSetting, dispatch]);

  return {
    inputDevice: userSettingInputDevice,
    language: userSettingLanguage,
    setInputDevice: changeInputDevice,
    setLanguage: changeLanguage,
    resetState,
  };
};
