import { useCallback, useRef } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

/**
 * 翻訳画面のリストビューの自動スクロール制御用カスタムフック
 *
 * ※スクロールが一番下にある場合のみ自動スクロールする
 * @returns
 */
export const useRecognizedListScroll = () => {
  const recognizedListRef = useRef<HTMLUListElement>(null);

  // スクロールが表示された後1回目の判定かどうか(true=1回目)
  const isFirstTimeScrollAfterVisibleScrollRef = useRef<boolean>(true);

  /**
   * スクロールが表示されているか否か
   *
   * @param ulElement ul要素
   * @returns true=スクロールがある
   */
  const isScrollVisible = (ulElement: HTMLUListElement): boolean => {
    if (
      Math.ceil(ulElement.scrollTop) + Math.ceil(ulElement.offsetHeight) <
      Math.ceil(ulElement.scrollHeight)
    ) {
      return true;
    }

    return false;
  };

  /**
   * スクロールバーが一番下にあるか否か
   * 最新の翻訳テキストの表示が先にされるため、その分の高さをマイナスして比較する
   *
   * @param ulElement ul要素
   * @param liElement li要素
   * @returns true=番下にスクロールバーがある
   */
  const isScrollBarBottom = (
    ulElement: HTMLUListElement,
    liElement: Element,
  ) => {
    const scrollBar =
      Math.ceil(ulElement.scrollTop) +
      Math.ceil(ulElement.offsetHeight) -
      (Math.ceil(ulElement.scrollHeight) - Math.ceil(liElement.clientHeight));

    // Math.ceil()で小数点切り上げられる
    // Chrome Bookで(scrollTop + offsetHeight) - (scrollHeight - clientHeight) = -1となる場合がある
    // 以上より-1以上であれば一番下にスクロールバーがあるとみなして自動スクロール
    if (scrollBar >= -1) {
      return true;
    }

    return false;
  };

  /**
   * リストビューのスクロール制御
   */
  const scrollListView = useCallback(() => {
    if (recognizedListRef.current === null) {
      return;
    }

    const view: HTMLUListElement = recognizedListRef.current;
    const viewLastChild = view.lastChild as Element;
    const elements =
      view.childNodes as unknown as HTMLCollectionOf<HTMLLIElement>;

    if (viewLastChild === null || elements === undefined) {
      // TTTテキストが1件も画面に表示されていない場合はreturn
      return;
    }

    if (!isScrollVisible(view)) {
      // スクロールが表示されていない場合は自動スクロール不要なのでreturn
      return;
    }

    if (
      !isScrollBarBottom(view, viewLastChild) &&
      !isFirstTimeScrollAfterVisibleScrollRef.current
    ) {
      // スクロールバーが一番下にないかつスクロールバー表示直後でない場合は自動スクロール不要なのでreturn
      return;
    }

    if (isFirstTimeScrollAfterVisibleScrollRef.current) {
      isFirstTimeScrollAfterVisibleScrollRef.current = false;
    }

    const len = elements.length;
    scrollIntoView(elements[len - 1], {
      scrollMode: 'always',
      block: 'start',
      inline: 'center',
    });
  }, []);

  return {
    recognizedListRef,
    scrollListView,
  };
};
