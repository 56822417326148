import React from 'react';

import { useMainLayout } from '@/hooks/useMainLayout';

import styles from './MainLayout.module.scss';
/**
 * プロパティ
 */
export type MainLayout = {
  children: React.ReactNode;
};

/**
 * メインレイアウト コンポーネント
 *
 * @param param0
 * @returns
 */
export const MainLayout = React.memo(({ children }: MainLayout) => {
  const layout = useMainLayout();

  return (
    <main className={styles['browser-web']} ref={layout.mainLayoutRef}>
      {children}
    </main>
  );
});
