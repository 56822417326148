import { Reducer, useCallback, useReducer } from 'react';

import { API_STATUS, ApiStatus } from '@/constants';

import {
  AGREEMENT_UPDATE_API_RESULT_CODE,
  AgreementUpdateApiResponse,
  isAgreementUpdateApiResultCode,
  agreementUpdateApi,
} from '../agreementUpdateApi';

/**
 * State
 */
export type AgreementUpdateState = {
  // レスポンスデータ
  data?: AgreementUpdateApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const AGREEMENT_UPDATE_ACTION_TYPE = {
  SET_AGREEMENT_UPDATE: 'SET_AGREEMENT_UPDATE',
  SET_AGREEMENT_UPDATE_SUCCESS: 'SET_AGREEMENT_UPDATE_SUCCESS',
  SET_AGREEMENT_UPDATE_FAILED: 'SET_AGREEMENT_UPDATE_FAILED',
  SET_AGREEMENT_UPDATE_IDLE: 'SET_AGREEMENT_UPDATE_IDLE',
} as const;

/**
 * Action
 */
type AgreementUpdateAction = {
  data?: AgreementUpdateApiResponse;
  type: keyof typeof AGREEMENT_UPDATE_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<AgreementUpdateState, AgreementUpdateAction> = (
  state: AgreementUpdateState,
  action: AgreementUpdateAction,
) => {
  switch (action.type) {
    case AGREEMENT_UPDATE_ACTION_TYPE.SET_AGREEMENT_UPDATE:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };
    case AGREEMENT_UPDATE_ACTION_TYPE.SET_AGREEMENT_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };
    case AGREEMENT_UPDATE_ACTION_TYPE.SET_AGREEMENT_UPDATE_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };
    case AGREEMENT_UPDATE_ACTION_TYPE.SET_AGREEMENT_UPDATE_IDLE:
      return {
        ...state,
        status: API_STATUS.IDLE,
      };

    default:
      return state;
  }
};

/**
 * 利用許諾API 呼び出しhooks
 *
 * @returns
 */
export const useAgreementUpdateApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const fetchAgreementUpdate = useCallback(() => {
    if (state.status === API_STATUS.LOADING) {
      return;
    }

    dispatch({ type: AGREEMENT_UPDATE_ACTION_TYPE.SET_AGREEMENT_UPDATE });

    agreementUpdateApi()
      .then((response: AgreementUpdateApiResponse) => {
        // 成功
        if (response.resultCode === AGREEMENT_UPDATE_API_RESULT_CODE.OK) {
          dispatch({
            type: AGREEMENT_UPDATE_ACTION_TYPE.SET_AGREEMENT_UPDATE_SUCCESS,
            data: response,
          });

          return;
        }

        // 意図しない結果コードの型だった場合は失敗とする
        if (!isAgreementUpdateApiResultCode(response.resultCode)) {
          dispatch({
            type: AGREEMENT_UPDATE_ACTION_TYPE.SET_AGREEMENT_UPDATE_FAILED,
            data: {
              ...response,
              resultCode: AGREEMENT_UPDATE_API_RESULT_CODE.ERR_UNKNOWN,
            } as AgreementUpdateApiResponse,
          });

          return;
        }

        // 失敗
        dispatch({
          type: AGREEMENT_UPDATE_ACTION_TYPE.SET_AGREEMENT_UPDATE_FAILED,
          data: response,
        });
      })
      .catch((_) => {
        dispatch({
          type: AGREEMENT_UPDATE_ACTION_TYPE.SET_AGREEMENT_UPDATE_FAILED,
          data: {
            resultCode: AGREEMENT_UPDATE_API_RESULT_CODE.ERR_UNKNOWN,
          } as AgreementUpdateApiResponse,
        });
      });
  }, [state.status]);

  /**
   * APIのステータスをリセットする
   */
  const resetAgreementUpdateState = useCallback(() => {
    dispatch({ type: AGREEMENT_UPDATE_ACTION_TYPE.SET_AGREEMENT_UPDATE_IDLE });
  }, []);

  return {
    agreementUpdateState: state,
    fetchAgreementUpdate,
    resetAgreementUpdateState,
  };
};
