/**
 * API認証失敗時の結果コード
 */
export const API_AUTH_RESULT_CODE = {
  // 認証失敗
  WARN_AUTH: 'WARN_AUTH',
  // IDトークン期限切れ
  INFO_EXPIRED_IDTOKEN: 'INFO_EXPIRED_IDTOKEN',
  // 利用規約の同意が必要
  INFO_NEED_AGREEMENT: 'INFO_NEED_AGREEMENT',
  // 利用規約バージョンマスタの登録に不備がある
  ERR_AGREEMENT_MASTER: 'ERR_AGREEMENT_MASTER',
} as const;

/**
 * API認証に必要なパラメータ
 */
export const API_AUTH_PARAM = {
  X_CF_TOKEN: 'X-CF-TOKEN',
} as const;

/**
 * API認証に必要なリクエストヘッダー
 * @param token セッション情報作成時に発行したトークン
 * @returns
 */
export const createAuthHeader = (token: string) => ({
  [API_AUTH_PARAM.X_CF_TOKEN]: token,
});
