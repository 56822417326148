import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTE_PATH } from '@/constants';

/**
 * 本カスタムフックからの返却値
 */
export type UseHomeValue = {
  // [マイク翻訳]ボタンがクリックされた場合の処理
  clickMicButton: () => void;
  // [スピーカー翻訳]ボタンがクリックされた場合の処理
  clickSpeakerButton: () => void;
};

/**
 * ホーム画面（モード選択画面） hooks
 */
export const useHome = (): UseHomeValue => {
  const navigate = useNavigate();

  /**
   * [マイク翻訳]ボタンがクリックされた場合の処理
   */
  const clickMicButton = useCallback(() => {
    // ホーム画面を削除するまではホーム画面からスピーカー画面に遷移できるようにしておく
    navigate(`${PAGE_ROUTE_PATH.SESSION.SPEAKER}/spOqkCk6YGvt2QLQ1pusPwGTswkx3raD`);
  }, [navigate]);

  /**
   * [スピーカー翻訳]ボタンがクリックされた場合の処理
   */
  const clickSpeakerButton = useCallback(() => {
    // ホーム画面を削除するまではホーム画面からオーディエンス画面に遷移できるようにしておく
    navigate(`${PAGE_ROUTE_PATH.SESSION.AUDIENCE}/aufkVG1CTdsNSbTrWjsilepQXSrsjLhX`);
  }, [navigate]);

  return {
    clickMicButton,
    clickSpeakerButton,
  };
};
