import { GOOGLE } from '@/constants';
import { googleApiV1Axios } from '@/lib/axios';

/**
 * リクエストパラメータ
 */
export type TTSApiRequest = {
  input: {
    // テキスト
    text: string;
  };
  voice: {
    // テキストの言語
    languageCode: string;
    // 音声の名前
    name: string;
  };
  audioConfig: {
    // エンコーディング
    audioEncoding: string;
    // 発声速度
    speakingRate: number;
    // ピッチ
    pitch: number;
  };
};

/**
 * レスポンス
 */
export type TTSApiResponse = {
  // 音声データ
  audioContent: string;
};

/**
 * API 呼び出し
 *
 * @param request
 * @returns
 */
const callApi = (request: TTSApiRequest): Promise<TTSApiResponse> => {
  // パラメータをJSON文字列に変換
  const params = JSON.stringify(request);

  // リクエスト送信（POST）
  const response = googleApiV1Axios
    .post<TTSApiResponse>(`${GOOGLE.API_URL.TTS}${GOOGLE.KEY.TTS}`, params, {
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * TTS API(エンジンサーバ) リクエスト
 *
 * @param request
 * @returns
 */
export const ttsApi = (request: TTSApiRequest): Promise<TTSApiResponse> =>
  callApi(request);
