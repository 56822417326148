// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
/**
 * プロパティ
 */
export type LinkText = {
  // リンク名(必須)
  title: string;
  // リンク先(必須)
  href: string;
  // 別タブで開くか否か(true=別タブで開く)
  isNewTab: boolean;
  // リンクに表示する画像(任意)
  imgURL?: string;
};

/**
 * リンク コンポーネント
 *
 * @param param0
 * @returns
 */
export const LinkText = ({
  title,
  href,
  isNewTab = false,
  imgURL = '',
}: LinkText) => (
  <a
    data-testid="link"
    target={isNewTab ? '_blank' : '_self'}
    href={href}
    rel={isNewTab ? 'noopener noreferrer' : ''}
  >
    <span data-testid="link-title">{title}</span>
    {imgURL && <img src={imgURL} alt="img" />}
  </a>
);
