import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { RecognizedItem } from '@/constants';
import {
  MicStatus,
  STTStatus,
  translationInfoSlice,
  TranslationInfoState,
  TTSStatus,
  recognizedListAdapterSelectors,
  SttErrorType,
  ttsRequestQueueAdapterSelectors,
  STT_STATUS,
  MIC_STATUS,
  TTSRequestInfo,
  audioUrlQueueAdapterSelectors,
  RetryStatus,
} from '@/states/slices/translationInfoSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

import { TTSApiRequest } from '../features/api/ttsApi';

/**
 * 翻訳に関する情報を保存 hooks
 *
 * @returns
 */
export const useTranslationInfo = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const {
    setLicenseToken,
    setMicStatus,
    setSttStatus,
    setTtsStatus,
    setRetryStatus,
    setRecognizedList,
    addNewRecognizedItem,
    setSttErrorType,
    setTtsRequestQueue,
    addNewTtsRequestQueue,
    setAudioUrlQueue,
    addNewAudioUrlQueue,
    resetToInitialState,
  } = translationInfoSlice.actions;

  const {
    licenseToken,
    micStatus,
    sttStatus,
    ttsStatus,
    retryStatus,
    sttErrorType,
  } = useSelector<RootState, TranslationInfoState>(
    (state) => state.translationInfo,
  );

  // 翻訳一覧
  const list = useSelector(recognizedListAdapterSelectors.selectAll);
  // TTSリクエスト情報格納キュー
  const ttsRequestQueue = useSelector(
    ttsRequestQueueAdapterSelectors.selectAll,
  );
  // TTSの音声ファイルURL格納キュー
  const audioUrlQueue = useSelector(audioUrlQueueAdapterSelectors.selectAll);

  /**
   * ライセンストークンを更新
   */
  const changeLicenseToken = useCallback(
    (value: string) => {
      dispatch(setLicenseToken(value));
    },
    [dispatch, setLicenseToken],
  );

  /**
   * マイク認識状況を更新
   */
  const changeMicStatus = useCallback(
    (value: MicStatus) => {
      dispatch(setMicStatus(value));
    },
    [dispatch, setMicStatus],
  );

  /**
   * 音声認識(収録)状況を更新
   */
  const changeSttStatus = useCallback(
    (value: STTStatus) => {
      dispatch(setSttStatus(value));
    },
    [dispatch, setSttStatus],
  );

  /**
   * 音声合成(読み上げ)状況を更新
   */
  const changeTtsStatus = useCallback(
    (value: TTSStatus) => {
      dispatch(setTtsStatus(value));
    },
    [dispatch, setTtsStatus],
  );

  /**
   * リトライ状況を更新
   */
  const changeRetryStatus = useCallback(
    (value: RetryStatus) => {
      dispatch(setRetryStatus(value));
    },
    [dispatch, setRetryStatus],
  );

  /**
   * 翻訳画面の表示内容を更新
   */
  const changeRecognizedList = useCallback(
    (value: RecognizedItem[]) => {
      dispatch(setRecognizedList(value));
    },
    [dispatch, setRecognizedList],
  );

  /**
   * 翻訳画面の表示内容を追加
   */
  const addRecognizedItem = useCallback(
    (value: RecognizedItem) => {
      dispatch(addNewRecognizedItem(value));
    },
    [dispatch, addNewRecognizedItem],
  );

  /**
   * 失敗理由更新
   */
  const changeSttErrorType = useCallback(
    (value: SttErrorType) => {
      dispatch(setSttErrorType(value));
    },
    [dispatch, setSttErrorType],
  );

  /**
   * TTSリクエスト情報格納キューを更新
   */
  const changeTtsRequestQueue = useCallback(
    (value: TTSRequestInfo[]) => {
      dispatch(setTtsRequestQueue(value));
    },
    [dispatch, setTtsRequestQueue],
  );

  /**
   * TTSリクエスト情報格納キューにリクエスト情報を追加
   */
  const addTtsRequestQueue = useCallback(
    (value: TTSRequestInfo) => {
      dispatch(addNewTtsRequestQueue(value));
    },
    [dispatch, addNewTtsRequestQueue],
  );

  /**
   * TTSリクエストを追加
   */
  const addTtsRequest = useCallback(
    (newRequest: TTSApiRequest) => {
      addTtsRequestQueue({
        id: uuidv4(),
        ttsRequest: newRequest,
      });
    },
    [addTtsRequestQueue],
  );

  /**
   * 未処理のTTSリクエスト情報が残っているか否か(true=残っている)
   */
  const hasTtsRequest = useMemo(() => {
    if (!ttsRequestQueue) {
      return false;
    }

    return ttsRequestQueue.length > 0;
  }, [ttsRequestQueue]);

  /**
   * キューからTTSリクエスト情報を取り出す
   */
  const shiftTtsRequestQueue = useCallback(() => {
    const ttsRequest = ttsRequestQueue.shift();
    changeTtsRequestQueue(ttsRequestQueue);

    return ttsRequest;
  }, [ttsRequestQueue, changeTtsRequestQueue]);

  /**
   * キューの中身を全て破棄
   */
  const clearTtsRequestQueue = useCallback(() => {
    changeTtsRequestQueue([]);
  }, [changeTtsRequestQueue]);

  /**
   * TTSの音声ファイルURL格納キューを更新
   */
  const changeAudioUrlQueue = useCallback(
    (value: string[]) => {
      dispatch(setAudioUrlQueue(value));
    },
    [dispatch, setAudioUrlQueue],
  );

  /**
   * TTSの音声ファイルURL格納キューにURLを追加
   */
  const addAudioUrlQueue = useCallback(
    (value: string) => {
      dispatch(addNewAudioUrlQueue(value));
    },
    [dispatch, addNewAudioUrlQueue],
  );

  /**
   * 未再生の音声ファイルURLが残っているか否か(true=残っている)
   */
  const hasAudioUrl = useMemo(() => {
    if (!audioUrlQueue) {
      return false;
    }

    return audioUrlQueue.length > 0;
  }, [audioUrlQueue]);

  /**
   * キューから音声ファイルURLを取り出す
   */
  const shiftAudioUrlQueue = useCallback(() => {
    const audioUrl = audioUrlQueue.shift();
    changeAudioUrlQueue(audioUrlQueue);

    return audioUrl;
  }, [audioUrlQueue, changeAudioUrlQueue]);

  /**
   * キューの中身を全て破棄
   */
  const clearAudioUrlQueue = useCallback(() => {
    changeAudioUrlQueue([]);
  }, [changeAudioUrlQueue]);

  /**
   * 翻訳に関する全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  /**
   * 翻訳機能が利用可能か否か(true=利用可能)
   */
  const isUseTranslation = useMemo(() => {
    if (sttStatus === STT_STATUS.READY) {
      return false; // 音声認識準備中
    }
    if (micStatus === MIC_STATUS.ERROR) {
      return false; // マイク認識失敗
    }

    return true;
  }, [micStatus, sttStatus]);

  /**
   * マイク認識状況がCONNECTING以外かつ翻訳一覧が空(初期状態)であるか
   * true = 初期状態
   */
  const isInitialState = useMemo(() => {
    if (sttStatus !== STT_STATUS.CONNECTING && list.length === 0) {
      return true;
    }

    return false;
  }, [list.length, sttStatus]);

  return {
    licenseToken,
    micStatus,
    sttStatus,
    ttsStatus,
    retryStatus,
    recognizedList: list,
    sttErrorType,
    setLicenseToken: changeLicenseToken,
    setMicStatus: changeMicStatus,
    setSttStatus: changeSttStatus,
    setTtsStatus: changeTtsStatus,
    setRetryStatus: changeRetryStatus,
    setRecognizedList: changeRecognizedList,
    addNewRecognizedItem: addRecognizedItem,
    setSttErrorType: changeSttErrorType,
    addTtsRequest,
    hasTtsRequest,
    shiftTtsRequestQueue,
    clearTtsRequestQueue,
    addNewAudioUrlQueue: addAudioUrlQueue,
    hasAudioUrl,
    shiftAudioUrlQueue,
    clearAudioUrlQueue,
    resetState,
    isUseTranslation,
    isInitialState,
  };
};
