// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import styles from './TextBlock.module.scss';

/**
 * テキストの水平方向の配置位置タイプ
 */
export const ALIGN_TYPE = {
  center: 'text-align-center',
  left: 'text-align-left',
  right: 'text-align-right',
};
export type AlignType = (typeof ALIGN_TYPE)[keyof typeof ALIGN_TYPE];

/**
 * フォントタイプ
 */
export const FONT_VARIANT_TYPE = {
  primary: 'font-variant-primary',
  alert: 'font-variant-alert',
};
export type FontVariantType =
  (typeof FONT_VARIANT_TYPE)[keyof typeof FONT_VARIANT_TYPE];

/**
 * フォントサイズタイプ
 */
export const FONT_SIZE_TYPE = {
  md: 'font-size-md',
  lg: 'font-size-lg',
  xl: 'font-size-xl',
};
export type FontSizeType = (typeof FONT_SIZE_TYPE)[keyof typeof FONT_SIZE_TYPE];

/**
 * プロパティ
 */
export type TextBlock = {
  // テキスト
  text: string;
  // テキストの水平方向の配置位置
  align?: AlignType;
  // フォントタイプ
  fontVariant?: FontVariantType;
  // フォントサイズ
  fontSize?: FontSizeType;
};

/**
 * テキストブロック コンポーネント
 *
 * @param param0
 * @returns
 */
export const TextBlock = ({
  text,
  align = ALIGN_TYPE.left,
  fontVariant = FONT_VARIANT_TYPE.primary,
  fontSize = FONT_SIZE_TYPE.md,
}: TextBlock) => (
  <p
    data-testid="text-block"
    className={`${styles[align]} ${styles[fontVariant]} ${styles[fontSize]} ${styles['text-block']}`}
  >
    {text}
  </p>
);
