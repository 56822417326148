import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

/**
 * 非対応ブラウザ警告ダイアログ プロパティ
 */
type UnSupportBrowserDialog = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * 非対応ブラウザ警告ダイアログ
 */
export const UnSupportBrowserDialog = React.memo(
  ({ isOpen, onClose }: UnSupportBrowserDialog) => {
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        title={t(
          'unSupportBrowser.ご利用のブラウザはサポートされていません',
        ).toString()}
        buttons={[{ label: 'OK', onClick: onClose }]}
      >
        <DialogMessageContents>
          {t(
            'unSupportBrowser.対応ブラウザをご確認の上、最新のブラウザをご利用ください。',
          )}
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
