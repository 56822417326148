import React, { useEffect } from 'react';

import { LIST_DISPLAY_TYPE, RecognizedList } from '@/constants';

import { useRecognizedListScroll } from '../../hooks/useRecognizedListScroll';

import styles from './ListView.module.scss';

/**
 * 音声認識テキストリストビュー コンポーネント
 */
export const RecognizedListView = React.memo(
  ({ lists, displayType }: RecognizedList) => {
    // 自動スクロール制御
    const autoScroll = useRecognizedListScroll();
    useEffect(() => {
      autoScroll.scrollListView();
    }); // 第二引数なしで、再レンダリングのたびに必ず実行

    return (
      <ul
        className={styles.recognized}
        data-testid="recognized-list-view"
        id="recognized-list-view"
        ref={autoScroll.recognizedListRef}
      >
        {displayType === LIST_DISPLAY_TYPE.onePhrase
          ? // 縦表示
            lists.map((content) => (
              <li key={content.id}>
                <div className={styles.translated}>{content.value.ttt}</div>
              </li> // ※Reactはindexの指定が必須(indexがあれば変更があった部分のみレンダリングされる)
            ))
          : // 横表示
            lists.map((content) => (
              <li key={content.id}>
                <div className={styles.raw}>{content.value.stt}</div>
                <div className={styles.translated}>{content.value.ttt}</div>
              </li>
            ))}
      </ul>
    );
  },
);
