import { useCallback, useEffect } from 'react';

import { FONT_SIZE_INFO } from '@/constants';
import { useTranslationDisplay } from '@/hooks/useTranslationDisplay';

/**
 * 本カスタムからの返却値
 */
export type Value = {
  // フォントサイズ変更が変更された場合の処理
  onChangeFontSize: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * フォントサイズ変更スライダー カスタムフック
 *
 * @returns
 */
export const useFontSizeInput = (): Value => {
  const { setFontSize } = useTranslationDisplay();

  /**
   * フォントサイズ変更が変更された場合の処理
   */
  const onChangeFontSize = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFontSize(Number(e.currentTarget.value));
    },
    [setFontSize],
  );

  /**
   * マウント時の処理
   */
  useEffect(() => {
    setFontSize(FONT_SIZE_INFO.defaultValue);

    // マウント時に実行させるので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onChangeFontSize,
  };
};
