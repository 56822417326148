// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';

import {
  ListDisplayType,
  LIST_DISPLAY_TYPE,
  RecognizedItem,
} from '@/constants';

import { InterimListView } from './InterimListView';
import styles from './ListView.module.scss';
import { RecognizedListView } from './RecognizedListView';

/**
 * プロパティ
 */
export type TranslationListView = {
  // 暫定テキスト
  interimText: string;
  // 音声認識結果/翻訳結果テキストリストの表示内容
  recognizedList: RecognizedItem[];
  // 表示タイプ
  displayType: ListDisplayType;
  // フォントサイズ
  fontSize?: number;
  // 文字サイズ単位
  point?: string;
  // 音声認識状況
  isInitialState: boolean;
  // フッター開閉状況
  isClose?: boolean;
};

/**
 * 暫定テキストビュー
 */
const InterimView = React.memo(({ text }: { text: string }) => (
  <InterimListView text={text} />
));

/**
 * 翻訳テキストビュー
 */
const RecognizedView = React.memo(
  ({
    lists,
    displayType,
  }: {
    lists: RecognizedItem[];
    displayType: ListDisplayType;
  }) => <RecognizedListView lists={lists} displayType={displayType} />,
);

/**
 * 同時通訳リストビュー コンポーネント
 *
 * @param param0
 * @returns
 */
export const TranslationListView = React.memo(
  ({
    interimText,
    recognizedList,
    displayType,
    fontSize,
    point,
    isInitialState,
    isClose,
  }: TranslationListView) => (
    <div
      className={`${styles.content} ${
        isClose ? styles['footer-close'] : styles['footer-open']
      }`}
    >
      <div
        className={`${styles.text} ${styles.conference}`}
        style={fontSize ? { fontSize: `${fontSize}${point}` } : undefined}
      >
        {!isInitialState &&
          (displayType === LIST_DISPLAY_TYPE.onePhrase ? (
            <div className={styles['one-phrase']} id="onePhrase">
              <RecognizedView
                lists={recognizedList}
                displayType={displayType}
              />
              <InterimView text={interimText} />
            </div>
          ) : (
            <div className={styles.list} id="list">
              <RecognizedView
                lists={recognizedList}
                displayType={displayType}
              />
              <InterimView text={interimText} />
            </div>
          ))}
      </div>
    </div>
  ),
);
