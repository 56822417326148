import { Buffer } from 'buffer';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { API_STATUS, RecognizedItem } from '@/constants';
import {
  SESSION_INFO_API_RESULT_CODE,
  STREAM_API_RESULT_CODE,
  StreamApiResponse,
  X_OPTION_VALUE,
  useSessionInfoApi,
} from '@/features/api';
import { useMic, useVoiceInput } from '@/features/recognition';
import { useLanguage } from '@/hooks/useLanguage';
import { useSpeakerInfo } from '@/hooks/useSpeakerInfo';
import { MIC_STATUS } from '@/states/slices/speakerInfoSlice';
import { STT_STATUS } from '@/states/slices/translationInfoSlice';
import { convertDateToYyyyMMddTHhmmssUTC, currentDateUTC } from '@/utils/date';

import { useBeforeUnload } from '../useBeforeUnload';

window.Buffer = Buffer;

/**
 * 本カスタムフックからの返却値
 */
export type Value = {
  // [STT ON/OFF]ボタンがクリックされた際の処理
  clickSttButton: () => void;
  // リアルタイムSTT結果
  interimText: string;
  // プログレスサークルを表示するか否か
  isVisibledSpinner: boolean;
};

/**
 * スピーカー画面 hooks
 *
 * @returns
 */
export const useSpeaker = ({ token }: { token: string }): Value => {
  // リアルタイムSTT結果
  const [interimText, setInterimText] = useState<string>('');

  const { sttStatus, micStatus, addNewRecognizedItem, recognizedList } =
    useSpeakerInfo();
  const { requestStartMic, startRecording, stopRecording } = useMic();
  const { sessionInfoState, fetchSessionInfo } = useSessionInfoApi();
  const { destlang } = useLanguage();

  /**
   * タブを閉じる、ブラウザを閉じる、更新による画面遷移を制御
   */
  useBeforeUnload();

  /**
   * 翻訳一覧の変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const recognizedListRef = useRef<RecognizedItem[]>(recognizedList);
  useEffect(() => {
    recognizedListRef.current = recognizedList;
  }, [recognizedList]);

  /**
   * 音声ストリームAPIから受け取った音声認識結果
   */
  const onRecognitionResult = useCallback(
    (response: StreamApiResponse) => {
      if (response.resultCode !== STREAM_API_RESULT_CODE.OK) {
        // 音声認識に失敗している場合は何もしない
        return;
      }

      // 音声認識結果のうち、選択中の翻訳先言語と一致しないものは何もしない
      if (response.destlang !== destlang) {
        return;
      }

      if (response.isFinal) {
        // 通訳データ
        const recognizedItem: RecognizedItem = {
          id: recognizedListRef.current.length + 1,
          value: {
            stt: response.stt,
            ttt: response.ttt,
            srclang: response.srclang,
            destlang: response.destlang,
            date: convertDateToYyyyMMddTHhmmssUTC(currentDateUTC()),
          },
        };
        // stt, ttt 結果をReduxに追加
        addNewRecognizedItem(recognizedItem);
        // 確定後はリアルタイムSTT結果を消去
        setInterimText('');
      } else {
        // リアルタイムSTT結果を表示
        setInterimText(response.stt);
      }
    },
    [addNewRecognizedItem, destlang],
  );

  /**
   * 音声入力 hooks
   */
  const { requestStartStream, closeStream, sendAudio } = useVoiceInput({
    token,
    onResult: onRecognitionResult,
    startAudioContext: startRecording,
    stopAudioContext: stopRecording,
    noiseValue: X_OPTION_VALUE.HIGH,
  });

  /**
   * マイク入力結果を音声ストリームAPIに渡す
   */
  const onMicInputResult = useCallback(
    (data: any) => {
      sendAudio(data);
    },
    [sendAudio],
  );

  /**
   * [STT ON/OFF]ボタンがクリックされた際の処理
   */
  const clickSttButton = useCallback(() => {
    // 音声認識開始
    if (sttStatus === STT_STATUS.PAUSED || sttStatus === STT_STATUS.INACTIVE) {
      requestStartStream();

      return;
    }

    // 音声認識停止
    if (sttStatus === STT_STATUS.CONNECTING) {
      closeStream();
      setInterimText(''); // リアルタイムSTT結果を消去
    }
  }, [closeStream, requestStartStream, sttStatus]);

  /**
   * プログレスサークルを表示するか否か
   * true = 表示する
   */
  const isVisibledSpinner = useMemo(() => {
    if (
      sessionInfoState.status === API_STATUS.IDLE ||
      sessionInfoState.status === API_STATUS.LOADING
    ) {
      return true;
    }

    if (sessionInfoState.status === API_STATUS.FAILED) {
      return false;
    }

    if (micStatus === MIC_STATUS.NONE) {
      return true;
    }

    return false;
  }, [micStatus, sessionInfoState]);

  /**
   * セッション情報取得APIが実行された時
   */
  useEffect(() => {
    if (
      micStatus === MIC_STATUS.NONE &&
      sessionInfoState.data?.resultCode === SESSION_INFO_API_RESULT_CODE.OK
    ) {
      // セッションが有効な場合のみマイク許可
      requestStartMic(onMicInputResult);
    }

    // TODO: セッション情報が取得できない場合はエラー表示
  }, [
    micStatus,
    onMicInputResult,
    requestStartMic,
    sessionInfoState.data?.resultCode,
  ]);

  /**
   * 画面表示
   */
  useEffect(() => {
    fetchSessionInfo({ token });

    // 画面表示処理のため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    clickSttButton,
    interimText,
    isVisibledSpinner,
  };
};
