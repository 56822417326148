/**
 * 対応したブラウザか否かをチェックする
 *
 * @returns true=対応したブラウザ/false=非対応のブラウザ
 */
export const isSupportBrowser = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('firefox') || userAgent.includes('fxios')) {
    return false;
  }

  // chrome関連を含んでいる場合は、一律true
  if (
    userAgent.includes('chrome') ||
    userAgent.includes('edg') ||
    userAgent.includes('crios')
  ) {
    return true;
  }

  // chrome関連がなく、safariが含まれている場合、iPhoneとiPadのみtrue
  if (userAgent.includes('safari')) {
    if ('ontouchend' in document) {
      return true;
    }
  }

  return false;
};
