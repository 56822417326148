export * from './api';
export * from './translation';
export * from './ui';
export * from './websocket';

/**
 * APIリクエストのタイムアウト時間
 */
export const DEFAULT_API_TIMEOUT = 30000 as const;

/**
 * 入力元/出力元デバイスの初期値
 */
export const DEFAULT_DEVICE = 'default' as string;

/**
 * ページURL
 */
export const PAGE_ROUTE_PATH = {
  // ホーム画面
  HOME: '/home',
  // カンファレンス画面
  SESSION: {
    // スピーカー
    SPEAKER: '/session/owner',
    // オーディエンス
    AUDIENCE: '/session/audience',
  },
  // 利用規約画面
  AGREEMENT: '/agreement',
  // 認証エラー画面
  AUTH_ERROR: '/auth/error',
} as const;

/**
 * UELパラメータに指定されるKey
 */
export const URL_PARAMS_KEY = {
  // 利用規約画面
  AGREEMENT: {
    path: 'path', // 呼び出し元のパス
  },
  // オーディエンス画面
  AUDIENCE: {
    // バックオフィスで生成したトークン
    token: 'token',
  },
  // スピーカー画面
  SPEAKER: {
    token: 'token', // セッション情報作成時に発行したトークン
  },
} as const;

/**
 * firestoreのパス一覧
 */
export const FIRESTORE_PATH = {
  // セッション情報
  SESSIONS: 'sessions',
  // 確定テキスト
  RECOGNIZED_TEXTS: 'recognized_texts',
  // 暫定テキスト
  INTERIM_TEXTS: 'interim_texts',
  // 言語コード
  LANGUAGES: 'languages',
};

/**
 * Payサーバ：新規作成メールアドレス入力画面 URL
 */
export const PAY_AAAWEB_URL = process.env.REACT_APP_PAY_AAAWEB_URL as string;

/**
 * ポケトーク同時通訳トップ画面
 */
export const POCKETALK_URL =
  'https://pocketalk.jp/forbusiness/livetranslation' as string;

/**
 * 利用規約 URL(日本語用)
 */
export const AGREEMENT_LINK_URL_JA =
  'https://pocketalk.co.jp/rule/livetranslation' as string;
/**
 * 利用規約 URL(その他用)
 */
export const AGREEMENT_LINK_URL_EN =
  'https://pocketalk.co.jp/rule/livetranslation/en-US' as string;

/**
 * TODO フィードバックURL
 */
export const FEEDBACK_LINK_URL = 'https://example.com/' as string;

/**
 * 利用権限
 * PermissionStateはprompt、granted、deniedの3種で固定
 */
export const PERMISSION_STATE = {
  // 確認する
  ASK: 'prompt',
  // 許可
  ALLOW: 'granted',
  // 不許可
  BLOCK: 'denied',
} as const;
