/**
 * カンファレンス版同時通訳サーバ
 */
export const CONFERENCE = {
  DOMAIN: {
    // APIドメイン
    API: process.env.REACT_APP_CONFERENCE_API_URL as string,
    // WebSocketドメイン
    WS: process.env.REACT_APP_CONFERENCE_WS_URL as string,
  },
  URL: {
    // ライセンストークン発行API
    LICENSE_CREATE: '/private/license/create',
    // ストリームID発行API
    CREATE: '/private/stream/create',
    // 音声ストリームAPI(WebSocket)
    STREAM: '/stream',
    // PTID認証API
    PTID: '/pub/ptid/auth',
    // 利用許諾API
    AGREEMENT_UPDATE: '/private/agreement/update',
    // セッション情報取得API
    SESSION_INFO: '/pub/session/info',
    // 翻訳先言語追加API
    LANG_ADD: '/private/lang/add',
  },
  // 言語一覧取得
  LANG_LIST_URL: process.env.REACT_APP_CONFERENCE_LANG_LIST_URL as string,
  // CODEC情報
  CODEC: 'PCM, 16000, 16, 1, L',
} as const;

/**
 * ポケトーク認証サーバ
 */
export const POCKETALK_AUTH = {
  DOMAIN: process.env.REACT_APP_POCKETALK_AUTH_API_URL as string,
  API_URL: {
    // ログイン認証
    AUTHORIZE: '/authorize',
  },
  // ポケトーク認証用:アプリケーション登録申請後に発行されたclient_id
  CLIENT_ID: process.env.REACT_APP_POCKETALK_AUTH_CLIENT_ID as string,
} as const;

/**
 * API呼び出し状況
 */
export const API_STATUS = {
  // 呼び出し前
  IDLE: 'idle',
  // 呼び出し中
  LOADING: 'loading',
  // 失敗
  FAILED: 'failed',
  // 成功
  SUCCESS: 'success',
} as const;
export type ApiStatus = (typeof API_STATUS)[keyof typeof API_STATUS];

/**
 * GOOGLE API
 */
export const GOOGLE = {
  DOMAIN: process.env.REACT_APP_GOOGLE_V1_DOMAIN as string,
  API_URL: {
    TTS: '/text:synthesize?key=',
  },
  KEY: {
    TTS: process.env.REACT_APP_GOOGLE_TTS_KEY as string,
  },
} as const;
