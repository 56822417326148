import React from 'react';
import { useTranslation } from 'react-i18next';

import { PullDownMenu, SpinnerOverlayView } from '@/components/Elements';
import { useLanguage } from '@/hooks/useLanguage';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { TTS_STATUS } from '@/states/slices/translationInfoSlice';

import { useAudienceFooter } from '../../hooks/footer/useAudienceFooter';
import { useTtsButton } from '../../hooks/footer/useTtsButton';
import { useLanguageMenu } from '../../hooks/useLanguageMenu';
import { AudienceMenuContainer } from '../menu/AudienceMenuContainer';
import styles from '../TranslationFooter.module.scss';

/**
 * オーディエンス画面_フッター コンポーネント
 */
export const AudienceFooter = React.memo(() => {
  const { ttsLangList, onChangeDestlang } = useLanguageMenu();
  const { isUseTranslation, ttsStatus } = useTranslationInfo();
  const { destlang } = useLanguage();
  const { isVisibledSpinner } = useAudienceFooter();
  const { clickTtsButton } = useTtsButton();
  const { t } = useTranslation();

  return (
    <div className={`${styles.footer} ${styles['footer-open']}`}>
      <SpinnerOverlayView isOpen={isVisibledSpinner} />
      <div>
        <div className={`${styles['footer-open']}`}>
          <div className={styles['footer-container']}>
            <div className={styles['translation-footer']}>
              <div className={styles['translation-button']}>
                <div className={styles['footer-translation-container']}>
                  {/* <div className={styles['footer-container-right']} /> */}
                </div>

                <div
                  className={`${styles['footer-translation-container']} ${styles['footer-container-bottom']}`}
                >
                  <div
                    className={`${styles['lang-list']} ${styles['footer-container-right']}`}
                  >
                    <PullDownMenu
                      name="tttLanguage"
                      optionItems={ttsLangList}
                      selectedValue={destlang}
                      onChange={onChangeDestlang}
                      disabled={ttsLangList.length < 1 || !isUseTranslation}
                    />
                  </div>
                </div>

                <div>
                  <button type="button" onClick={clickTtsButton}>
                    {ttsStatus === TTS_STATUS.READING
                      ? t('mainMenu.通訳音声を停止')
                      : t('mainMenu.通訳音声を再生')}{' '}
                  </button>
                </div>

                <div>
                  <AudienceMenuContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
