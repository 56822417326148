import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LIST_DISPLAY_TYPE, ListDisplayType } from '@/constants';

/**
 * State
 */
export type TranslationDisplayState = {
  // 翻訳元テキストの表示/非表示を管理
  displayType: ListDisplayType;
  // 翻訳関連テキストの文字サイズを管理
  fontSize: number;
};

/**
 * 初期State
 */
const initialState: TranslationDisplayState = {
  displayType: LIST_DISPLAY_TYPE.onePhrase,
  fontSize: 100,
};

/**
 * 翻訳画面の表示に関する情報を管理
 */
export const translationDisplaySlice = createSlice({
  name: 'translationDisplay',
  initialState,
  reducers: {
    // 翻訳元テキストの表示/非表示を更新
    setDisplayType: (state, action: PayloadAction<ListDisplayType>) => {
      state.displayType = action.payload;
    },
    // 翻訳関連テキストの文字サイズを更新
    setFontSize: (state, action: PayloadAction<number>) => {
      state.fontSize = action.payload;
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
