import { useCallback } from 'react';

import { POCKETALK_URL } from '@/constants';

/**
 * 認証エラー画面 hooks
 *
 * @returns
 */
export const useAuthError = () => {
  /**
   * [OK]ボタンがクリックされた場合の処理
   */
  const clickOKButton = useCallback(() => {
    // 同タブ内でポケトークページに遷移
    window.open(POCKETALK_URL, '_self');
  }, []);

  return {
    clickOKButton,
  };
};
