import { useCallback } from 'react';

import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { TTS_STATUS } from '@/states/slices/translationInfoSlice';

/**
 * 本カスタムフックからの返却値
 */
export type Value = {
  // [TTS ON/OFF]ボタンがクリックされた際の処理
  clickTtsButton: () => void;
};

/**
 * TTS ON/OFFボタン hooks
 */
export const useTtsButton = (): Value => {
  const { ttsStatus, setTtsStatus } = useTranslationInfo();

  /**
   * [TTS ON/OFF]ボタンがクリックされた際の処理
   */
  const clickTtsButton = useCallback(() => {
    // 読み上げ開始
    if (ttsStatus === TTS_STATUS.PAUSED || ttsStatus === TTS_STATUS.INACTIVE) {
      // 状態を「読み上げ中」に更新
      setTtsStatus(TTS_STATUS.READING);

      return;
    }

    // 読み上げ停止
    if (ttsStatus === TTS_STATUS.READING) {
      // 状態を「停止中」に更新
      setTtsStatus(TTS_STATUS.PAUSED);
    }
  }, [setTtsStatus, ttsStatus]);

  return {
    clickTtsButton,
  };
};
