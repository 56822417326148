/**
 * Websocketクローズコード
 *
 * 以下のコードを定数化
 * https://developer.mozilla.org/ja/docs/Web/API/CloseEvent/code
 */
export const WEBSOCKET_CLOSE_CODE = {
  /**
   * 通常のクローズ
   */
  NORMAL_CLOSURE: 1000,
  /**
   * 消滅
   */
  GOING_AWAY: 1001,
  /**
   * プロトコルエラー
   */
  PROTOCOL_ERROR: 1002,
  /**
   * 未対応のデータ
   */
  UNSUPPORTED_DATA: 1003,
  /**
   * 予約
   * ※意味は今後定義されるかもしれない。
   */
  RESERVATION: 1004,
  /**
   * ステータス未受信
   */
  NO_STATUS_RECEIVED: 1005,
  /**
   * 異常クローズ
   */
  ABNORMAL_CLOSURE: 1006,
  /**
   * フレームペイロードデータが無効
   */
  INVALID_FRAME_PAYLOAD: 1007,
  /**
   * ポリシー違反
   */
  POLICY_VIOLATION: 1008,
  /**
   * メッセージが大きすぎる
   */
  MESSAGE_TOO_BIG: 1009,
  /**
   * 必須の拡張機能
   */
  MISSING_EXTENSION: 1010,
  /**
   * 内部エラー
   */
  INTERNAL_SERVER_ERROR: 1011,
  /**
   * サービス再起動
   */
  SERVICE_RESTART: 1012,
  /**
   * 後で再試行
   */
  TRY_AGAIN_LATER: 1013,
  /**
   * ゲートウェイ違反
   */
  BAD_GATEWAY: 1014,
  /**
   * TLS ハンドシェイク
   */
  BAD_TLS_HANDSHAKE: 1015,
};
