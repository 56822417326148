import { useMemo } from 'react';

import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { STT_STATUS } from '@/states/slices/translationInfoSlice';

/**
 * オーディエンス画面フッターに関するhooks
 * @returns
 */
export const useAudienceFooter = () => {
  const { sttStatus } = useTranslationInfo();

  /**
   * プログレスの表示
   * true = 表示する
   */
  const isVisibledSpinner = useMemo(() => {
    if (sttStatus === STT_STATUS.READY) {
      return true;
    }

    if (sttStatus === STT_STATUS.CONNECTING) {
      return false;
    }

    return false;
  }, [sttStatus]);

  return {
    isVisibledSpinner,
  };
};
