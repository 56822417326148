import { convertCamelCaseToSnakeCase } from './namingStyleConverter';

/**
 * 対象オブジェクトのキーをスネークケースのJSONに変換する
 * ※ただし、入れ子構造や配列は未対応
 *
 * @param target 対象
 * @returns キーがスネークケースのJSON形式オブジェクト
 */
export const convertObjectToSneakJson = (target: object) => {
  const json: { [key: string]: string } = {};

  Object.entries(target).forEach((e) => {
    const k = e[0];
    const v = e[1] as unknown;
    const sneakCaseKey = convertCamelCaseToSnakeCase(k);

    json[sneakCaseKey] = String(v);
  });

  return json;
};

/**
 * 対象オブジェクトのキーをスネークケースに変換してGETパラメータ形式に変換する
 * ※ただし、入れ子構造や配列は未対応
 *
 * @param target
 * @returns
 */
export const convertObjectToSneakUrlParams = (target: object) => {
  const params = [] as string[];
  Object.entries(target).forEach((e) => {
    const k = e[0];
    const v = e[1] as unknown;
    const sneakCaseKey = convertCamelCaseToSnakeCase(k);

    params.push(`${sneakCaseKey}=${String(v)}`);
  });

  return params.join('&');
};
