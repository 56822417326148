// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';

import close from '@/assets/close.svg';
import info from '@/assets/info.svg';
import warning from '@/assets/warning.svg';

import styles from './Toast.module.scss';

/**
 * アイコンタイプ
 */
export const TOAST_ICON_TYPE = {
  // 情報アイコン
  INFO: 'info',
  // 警告アイコン
  WARNING: 'warning',
};
export type ToastIconType =
  (typeof TOAST_ICON_TYPE)[keyof typeof TOAST_ICON_TYPE];

/**
 * プロパティ
 */
export type Toast = {
  // DOMが更新されるように一意となるid(key)を指定(keyが無いと同じ要素扱いとなりトーストが複数表示できない)
  id: string;
  // アイコンタイプ(表示しない場合はundefined)
  iconType?: ToastIconType | undefined;
  // メッセージ
  message: string;
  // 表示するか否か(true=表示)
  visible: boolean;
  // 表示完了後に実行したい処理
  visibleEnd: () => void;
  // クローズボタンを非表示にするか(true=非表示|false=表示)
  isHiddenCloseButton?: boolean;
  // クローズボタンクリック時の処理
  onClickClose?: () => void;
};

/**
 * トーストコンポーネント
 *
 * @returns
 */
export const Toast = React.memo(
  ({
    id,
    iconType = undefined,
    message,
    visible,
    visibleEnd,
    isHiddenCloseButton = true,
    onClickClose,
  }: Toast) => (
    <div
      key={id}
      id="toast-area"
      className={`${styles.toastArea} ${visible ? `${styles.animation}` : ''}`}
      onAnimationEnd={() => {
        visibleEnd();
      }}
    >
      {message && (
        <div className={styles['toast-container']}>
          <div className={styles['toast-message']}>
            {iconType && (
              <div className={styles['toast-icon']}>
                {iconType === TOAST_ICON_TYPE.WARNING ? (
                  <img src={warning} alt="warning" />
                ) : (
                  <img src={info} alt="info" />
                )}
              </div>
            )}
            {/** メッセージ */}
            <p>{message}</p>
          </div>

          {/** クローズボタン */}
          {!isHiddenCloseButton && (
            <div className={styles['toast-close-button']}>
              <button type="button" onClick={onClickClose}>
                <img src={close} alt="close" />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  ),
);
