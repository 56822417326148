/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import arrow from '@/assets/arrow.svg';
import { PullDownMenu, SpinnerOverlayView } from '@/components/Elements';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useLanguage } from '@/hooks/useLanguage';
import { useSpeakerInfo } from '@/hooks/useSpeakerInfo';

import { useDeviceMenu } from '../../../device/hooks/useDeviceMenu';
import { useSpeakerFooter } from '../../hooks/footer/useSpeakerFooter';
import { useLanguageMenu } from '../../hooks/useLanguageMenu';
import styles from '../TranslationFooter.module.scss';

/**
 * プロパティ
 */
export type Props = {
  // [STT ON/OFF]ボタンがクリックされた場合の処理
  onClickSttButton: () => void;
};

/**
 * スピーカー画面_フッター コンポーネント
 */
export const SpeakerFooter = React.memo(({ onClickSttButton }: Props) => {
  const { sttLangList, ttsLangList, onChangeSrclang, onChangeDestlang } =
    useLanguageMenu();
  const { isUseTranslation } = useSpeakerInfo();
  const { destlang, srclang } = useLanguage();
  const { inputDevice } = useBrowserUserSetting();
  const { inputDeviceList, onChangeInputDevice } = useDeviceMenu();
  const { isVisibledSpinner, fetchSttButtonImg, isSttConnecting } = useSpeakerFooter();

  return (
    <div className={`${styles.footer} ${styles['footer-open']}`}>
      <SpinnerOverlayView isOpen={isVisibledSpinner} />
      <div>
        <div className={`${styles['footer-open']}`}>
          <div className={styles['footer-container']}>
            <div className={styles['translation-footer']}>
              <div className={styles['translation-button']}>
                <div className={styles['footer-translation-container']}>
                  <button
                    type="button"
                    onClick={onClickSttButton}
                    disabled={!isUseTranslation}
                    className={`${styles['img-btn']} ${
                      styles[fetchSttButtonImg]
                    } ${
                      fetchSttButtonImg.endsWith('off')
                        ? styles['ripple-btn-off']
                        : styles['ripple-btn-on']
                    }`}
                  />
                  <div className={styles['footer-container-right']} />
                </div>
                <div
                  className={`${styles['stt-device']} ${styles['footer-translation-container']}`}
                >
                  <div className={styles['device-name']}>
                    <p>
                      <PullDownMenu
                        optionItems={inputDeviceList}
                        name="micSelect"
                        selectedValue={inputDevice}
                        onChange={onChangeInputDevice}
                      />
                    </p>
                  </div>
                </div>

                <div
                  className={`${styles['footer-translation-container']} ${styles['footer-container-bottom']}`}
                >
                  <div
                    className={`${styles['lang-list']} ${styles['footer-container-left']}`}
                  >
                    <PullDownMenu
                      name="sttLanguage"
                      optionItems={sttLangList}
                      selectedValue={srclang}
                      onChange={onChangeSrclang}
                      disabled={sttLangList.length < 1 || !isUseTranslation || isSttConnecting}
                    />
                  </div>
                  <div className={styles['lang-list']}>
                    <img src={arrow} alt="from-to" />
                  </div>
                  <div
                    className={`${styles['lang-list']} ${styles['footer-container-right']}`}
                  >
                    <PullDownMenu
                      name="tttLanguage"
                      optionItems={ttsLangList}
                      selectedValue={destlang}
                      onChange={onChangeDestlang}
                      disabled={ttsLangList.length < 1 || !isUseTranslation || isSttConnecting}
                    />
                  </div>
                  <button disabled>配信停止中</button>
                  <button disabled>観客プレビュー</button>
                  <button disabled>ダウンロード</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
