import { Reducer, useCallback, useReducer } from 'react';

import { ApiStatus, API_STATUS } from '@/constants';

import {
  sessionInfoApi,
  SessionInfoApiResponse,
  isSessionInfoApiResultCode,
  SESSION_INFO_API_RESULT_CODE,
  SessionInfoApiRequest,
} from '../sessionInfoApi';

/**
 * State
 */
export type SessionInfoState = {
  // レスポンスデータ
  data?: SessionInfoApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const SESSION_INFO_ACTION_TYPE = {
  FETCH_SESSION_INFO: 'FETCH_SESSION_INFO',
  FETCH_SESSION_INFO_SUCCESS: 'FETCH_SESSION_INFO_SUCCESS',
  FETCH_SESSION_INFO_FAILED: 'FETCH_SESSION_INFO_FAILED',
} as const;

/**
 * Action
 */
type SessionInfoAction = {
  data?: SessionInfoApiResponse;
  type: keyof typeof SESSION_INFO_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<SessionInfoState, SessionInfoAction> = (
  state: SessionInfoState,
  action: SessionInfoAction,
) => {
  switch (action.type) {
    case SESSION_INFO_ACTION_TYPE.FETCH_SESSION_INFO:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };

    case SESSION_INFO_ACTION_TYPE.FETCH_SESSION_INFO_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };

    case SESSION_INFO_ACTION_TYPE.FETCH_SESSION_INFO_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };
    default:
      return state;
  }
};

/**
 * セッション情報取得API 呼び出しhooks
 *
 * @returns
 */
export const useSessionInfoApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const fetchSessionInfo = useCallback(
    (request: SessionInfoApiRequest) => {
      if (state.status === API_STATUS.LOADING) {
        return;
      }

      dispatch({ type: SESSION_INFO_ACTION_TYPE.FETCH_SESSION_INFO });

      sessionInfoApi(request)
        .then((response: SessionInfoApiResponse) => {
          // 成功
          if (response.resultCode === SESSION_INFO_API_RESULT_CODE.OK) {
            dispatch({
              type: SESSION_INFO_ACTION_TYPE.FETCH_SESSION_INFO_SUCCESS,
              data: response,
            });

            return;
          }

          // 意図しない結果コードの型だった場合は失敗とする
          if (!isSessionInfoApiResultCode(response.resultCode)) {
            dispatch({
              type: SESSION_INFO_ACTION_TYPE.FETCH_SESSION_INFO_FAILED,
              data: {
                ...response,
                resultCode: SESSION_INFO_API_RESULT_CODE.ERR_UNKNOWN,
              } as SessionInfoApiResponse,
            });

            return;
          }

          // 失敗
          dispatch({
            type: SESSION_INFO_ACTION_TYPE.FETCH_SESSION_INFO_FAILED,
            data: response,
          });
        })
        .catch((_) => {
          dispatch({
            type: SESSION_INFO_ACTION_TYPE.FETCH_SESSION_INFO_FAILED,
            data: {
              resultCode: SESSION_INFO_API_RESULT_CODE.ERR_UNKNOWN,
            } as SessionInfoApiResponse,
          });
        });
    },
    [state.status],
  );

  return {
    sessionInfoState: state,
    fetchSessionInfo,
  };
};
