import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { languageSlice, LanguageState } from '@/states/slices/languageSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * 翻訳元言語/翻訳先言語をReduxに保存 hooks
 * 言語=翻訳画面で選択された値
 *
 * @returns
 */
export const useLanguage = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setSrclang, setDestlang, clearLanguage } = languageSlice.actions;

  const { srclang, destlang } = useSelector<RootState, LanguageState>(
    (state) => state.browserLanguage,
  );

  /**
   * 翻訳元言語を更新
   */
  const changeSrclang = useCallback(
    (value: string) => {
      dispatch(setSrclang(value));
    },
    [dispatch, setSrclang],
  );

  /**
   * 翻訳先言語を更新
   */
  const changeDestlang = useCallback(
    (value: string) => {
      dispatch(setDestlang(value));
    },
    [dispatch, setDestlang],
  );

  /**
   * 言語に関する全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(clearLanguage());
  }, [dispatch, clearLanguage]);

  return {
    srclang,
    destlang,
    setSrclang: changeSrclang,
    setDestlang: changeDestlang,
    resetState,
  };
};
