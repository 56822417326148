import React from 'react';

/**
 * プロパティ
 */
export type FooterLayout = {
  children: React.ReactNode;
};

/**
 * フッターコンポーネント
 *
 * @param param0
 */
export const FooterLayout = React.memo(({ children }: FooterLayout) => (
  <div>{children}</div>
));
