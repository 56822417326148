import { PullDownMenuItem } from '@/components/Elements';

/**
 * 言語の型
 */
type LangType = {
  [key: string]: string;
};

/**
 * 言語
 */
export const LOCALE_LANG: LangType = {
  // 日本語
  JA: 'ja',
  // 英語
  EN: 'en',
  // 中国語（簡体字）
  ZH_CN: 'zh-CN',
  // 韓国語
  KO: 'ko',
  // スペイン語
  ES: 'es',
  // フランス語
  FR: 'fr',
  // ドイツ語
  DE: 'de',
  // イタリア語
  IT: 'it',
  // マレー語
  MS: 'ms',
  // ポルトガル語
  PT: 'pt',
  // ロシア語
  RU: 'ru',
  // タイ語
  TH: 'th',
  // 中国語（繁体字）
  ZH_TW: 'zh-TW',
} as const;
export type LocaleLang = (typeof LOCALE_LANG)[keyof typeof LOCALE_LANG];

/**
 * UI言語の初期値
 */
export const DEFAULT_UI_LANG = LOCALE_LANG.EN;

/**
 * UI言語一覧
 */
export const UILanguageList: PullDownMenuItem[] = [
  { label: '日本語', value: LOCALE_LANG.JA },
  { label: 'English', value: LOCALE_LANG.EN },
  { label: '中文(簡体)', value: LOCALE_LANG.ZH_CN },
  { label: '한국어', value: LOCALE_LANG.KO },
  { label: 'Español', value: LOCALE_LANG.ES },
  { label: 'Français', value: LOCALE_LANG.FR },
  { label: '中文(繁體)', value: LOCALE_LANG.ZH_TW },
  { label: 'Italiano', value: LOCALE_LANG.IT },
  { label: 'Deutsch', value: LOCALE_LANG.DE },
  { label: 'Melayu', value: LOCALE_LANG.MS },
  { label: 'Português', value: LOCALE_LANG.PT },
  { label: 'русский', value: LOCALE_LANG.RU },
  { label: 'ภาษาไทย', value: LOCALE_LANG.TH },
];

/**
 * PT_PAY用言語コード
 */
export const PT_PAY_LANG: LangType = {
  // 日本語
  JA_JP: 'ja-jp',
  // 英語
  EN_US: 'en-us',
  // 中国語（簡体字）
  ZH_CN: 'zh-cn',
  // 韓国語
  KO_KR: 'ko-kr',
  // スペイン語
  ES_ES: 'es-es',
  // フランス語
  FR_FR: 'fr-fr',
  // ドイツ語
  DE_DE: 'de-de',
  // イタリア語
  IT_IT: 'it-it',
  // マレー語
  MS_MY: 'ms-my',
  // ポルトガル語
  PT_PT: 'pt-pt',
  // ロシア語
  RU_RU: 'ru-ru',
  // タイ語
  TH_TH: 'th-th',
  // 中国語（繁体字）
  ZH_TW: 'zh-tw',
} as const;
export type PTPayLang = (typeof PT_PAY_LANG)[keyof typeof PT_PAY_LANG];

/**
 * PTPay言語コードMAP
 */
export const PTPayLangCodeMap: { [key in LocaleLang]: PTPayLang } = {
  [LOCALE_LANG.JA]: PT_PAY_LANG.JA_JP,
  [LOCALE_LANG.EN]: PT_PAY_LANG.EN_US,
  [LOCALE_LANG.ZH_CN]: PT_PAY_LANG.ZH_CN,
  [LOCALE_LANG.KO]: PT_PAY_LANG.KO_KR,
  [LOCALE_LANG.ES]: PT_PAY_LANG.ES_ES,
  [LOCALE_LANG.FR]: PT_PAY_LANG.FR_FR,
  [LOCALE_LANG.DE]: PT_PAY_LANG.DE_DE,
  [LOCALE_LANG.IT]: PT_PAY_LANG.IT_IT,
  [LOCALE_LANG.MS]: PT_PAY_LANG.MS_MY,
  [LOCALE_LANG.PT]: PT_PAY_LANG.PT_PT,
  [LOCALE_LANG.RU]: PT_PAY_LANG.RU_RU,
  [LOCALE_LANG.TH]: PT_PAY_LANG.TH_TH,
  [LOCALE_LANG.ZH_TW]: PT_PAY_LANG.ZH_TW,
};
