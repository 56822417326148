import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { audioInfoSlice } from './slices/audioInfoSlice';
import { browserUserSettingSlice } from './slices/browserUserSettingSlice';
import * as languageSlice from './slices/languageSlice';
import { speakerInfoSlice } from './slices/speakerInfoSlice';
import { toastInfoSlice } from './slices/toastInfoSlice';
import { translationDisplaySlice } from './slices/translationDisplaySlice';
import { translationInfoSlice } from './slices/translationInfoSlice';

const rootReducer = combineReducers({
  [languageSlice.languageSlice.name]: languageSlice.languageSlice.reducer,
  [translationInfoSlice.name]: translationInfoSlice.reducer,
  [speakerInfoSlice.name]: speakerInfoSlice.reducer,
  [audioInfoSlice.name]: audioInfoSlice.reducer,
  [translationDisplaySlice.name]: translationDisplaySlice.reducer,
  [toastInfoSlice.name]: toastInfoSlice.reducer,
  [browserUserSettingSlice.name]: browserUserSettingSlice.reducer,
});

// https://github.com/rt2zz/redux-persist/issues/988
// こちらを参考に「persist」キーの「serializable」のチェックを無視
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export default store;
