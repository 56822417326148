import { CONFERENCE } from '@/constants';
import { conferenceAxios } from '@/lib/axios';

import { API_AUTH_RESULT_CODE, createAuthHeader } from './apiAuth';

/**
 * リクエストパラメータ
 */
export type LangAddApiRequest = {
  // トークン
  destlang: string;
};

/**
 * 結果コード定数
 */
export const LANG_ADD_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // 既に追加済みの言語コードが指定された場合
  INFO_ALREADY_LANG: 'INFO_ALREADY_LANG',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 不明エラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',

  ...API_AUTH_RESULT_CODE,
} as const;
export type LangAddResultCode =
  (typeof LANG_ADD_API_RESULT_CODE)[keyof typeof LANG_ADD_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isLangAddApiResultCode = (
  resultCode: string,
): resultCode is LangAddResultCode => {
  if (resultCode in LANG_ADD_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type LangAddApiResponse = {
  // 結果コード
  resultCode: LangAddResultCode;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (
  request: LangAddApiRequest,
  token: string,
): Promise<LangAddApiResponse> => {
  const reqHeaders = {
    'Content-Type': 'application/json',
    ...createAuthHeader(token),
  };
  // リクエスト送信(POST)
  const response = conferenceAxios
    .post<LangAddApiResponse>(CONFERENCE.URL.LANG_ADD, request, {
      headers: reqHeaders,
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * 翻訳先言語追加APIリクエスト
 *
 * @returns
 */
export const langAddApi = (
  request: LangAddApiRequest,
  token: string,
): Promise<LangAddApiResponse> => callApi(request, token);
