import { useMemo } from 'react';

import { useSpeakerInfo } from '@/hooks/useSpeakerInfo';
import { STT_STATUS } from '@/states/slices/speakerInfoSlice';

/**
 * スピーカー画面フッターに関するhooks
 * @returns
 */
export const useSpeakerFooter = () => {
  const { sttStatus } = useSpeakerInfo();

  /**
   * プログレスの表示
   * true = 表示する
   */
  const isVisibledSpinner = useMemo(() => {
    if (sttStatus === STT_STATUS.READY) {
      return true;
    }

    if (sttStatus === STT_STATUS.CONNECTING) {
      return false;
    }

    return false;
  }, [sttStatus]);

  /**
   * フッターのSTTボタン画像の出し分け判定
   */
  const fetchSttButtonImg = useMemo(() => {
    if (sttStatus === STT_STATUS.CONNECTING) {
      return `mic-on`;
    }

    return `mic-off`;
  }, [sttStatus]);

  /**
   * STT利用中か否か
   */
  const isSttConnecting = useMemo(() => {
    if (sttStatus === STT_STATUS.CONNECTING) {
      return true;
    }

    return false;
  }, [sttStatus]);

  return {
    isVisibledSpinner,
    fetchSttButtonImg,
    isSttConnecting,
  };
};
