import React from 'react';
// 現状取り急ぎ日本語表示のみの対応で後々多言語化するのでコメントアウトにしておきます。
// import { useTranslation } from 'react-i18next';

import { ContentLayout } from '@/components/Layout';
import { isSupportBrowser } from '@/utils/browser';
import { isMobile } from '@/utils/device';

import { useHome } from '../hooks/useHome';

import styles from './Home.module.scss';
import { UnSupportBrowserDialogContainer } from './UnSupportBrowserDialogContainer';

/**
 * ホーム画面（モード選択画面） 子コンポーネント
 *
 * @returns
 */
const HomeView = React.memo(() => {
  // hooks
  const { clickMicButton, clickSpeakerButton } = useHome();
  // 現状取り急ぎ日本語表示のみの対応で後々多言語化するのでコメントアウトにしておきます。
  // const { t } = useTranslation();

  return (
    <>
      <UnSupportBrowserDialogContainer />
      <div className={styles.home}>
        <div className={styles['select-translation']}>
          <div className={styles['select-button']}>
            {isSupportBrowser() && (
              <button
                type="button"
                onClick={clickMicButton}
                className={styles['select-mic']}
                aria-label="スピーカー画面"
              >
                スピーカー画面
              </button>
            )}
          </div>

          {!isMobile() && isSupportBrowser() && (
            <div className={styles['select-button']}>
              <button
                type="button"
                onClick={clickSpeakerButton}
                className={styles['select-speaker']}
                aria-label="オーディエンス画面"
              >
                オーディエンス画面
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

/**
 * ホーム画面（モード選択画面）
 *
 * @returns
 */
export const Home = React.memo(() => (
  <ContentLayout>
    <HomeView />
  </ContentLayout>
));
