import React from 'react';

import { Toast } from '@/components/Elements';
import { useToastInfo } from '@/hooks/useToastInfo';

import { useToast } from '../hooks/useToast';

/**
 * トーストメッセージ表示管理コンポーネント
 */
export const ToastContainer = React.memo(() => {
  const { isToastVisible, latestMessageData } = useToastInfo();
  const { visibleEnd, onClose } = useToast();

  return (
    <Toast
      id={latestMessageData ? latestMessageData.id : ''} // DOMが更新されるように一意となるid(key)を指定
      iconType={latestMessageData?.iconType}
      visible={isToastVisible}
      message={latestMessageData ? latestMessageData.message : ''}
      visibleEnd={visibleEnd}
      isHiddenCloseButton={false}
      onClickClose={onClose}
    />
  );
});
