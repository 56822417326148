import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RecognizedItem } from '@/constants';
import {
  MicStatus,
  STTStatus,
  speakerInfoSlice,
  SpeakerInfoState,
  recognizedListAdapterSelectors,
  SttErrorType,
  STT_STATUS,
  MIC_STATUS,
} from '@/states/slices/speakerInfoSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * 翻訳に関する情報を保存 hooks
 *
 * @returns
 */
export const useSpeakerInfo = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const {
    setLicenseToken,
    setMicStatus,
    setSttStatus,
    setRecognizedList,
    addNewRecognizedItem,
    setSttErrorType,
    resetToInitialState,
  } = speakerInfoSlice.actions;

  const { licenseToken, micStatus, sttStatus, sttErrorType } =
    useSelector<RootState, SpeakerInfoState>((state) => state.speakerInfo);

  // 翻訳一覧
  const list = useSelector(recognizedListAdapterSelectors.selectAll);

  /**
   * ライセンストークンを更新
   */
  const changeLicenseToken = useCallback(
    (value: string) => {
      dispatch(setLicenseToken(value));
    },
    [dispatch, setLicenseToken],
  );

  /**
   * マイク認識状況を更新
   */
  const changeMicStatus = useCallback(
    (value: MicStatus) => {
      dispatch(setMicStatus(value));
    },
    [dispatch, setMicStatus],
  );

  /**
   * 音声認識(収録)状況を更新
   */
  const changeSttStatus = useCallback(
    (value: STTStatus) => {
      dispatch(setSttStatus(value));
    },
    [dispatch, setSttStatus],
  );

  /**
   * 翻訳画面の表示内容を更新
   */
  const changeRecognizedList = useCallback(
    (value: RecognizedItem[]) => {
      dispatch(setRecognizedList(value));
    },
    [dispatch, setRecognizedList],
  );

  /**
   * 翻訳画面の表示内容を追加
   */
  const addRecognizedItem = useCallback(
    (value: RecognizedItem) => {
      dispatch(addNewRecognizedItem(value));
    },
    [dispatch, addNewRecognizedItem],
  );

  /**
   * 失敗理由更新
   */
  const changeSttErrorType = useCallback(
    (value: SttErrorType) => {
      dispatch(setSttErrorType(value));
    },
    [dispatch, setSttErrorType],
  );

  /**
   * 翻訳に関する全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  /**
   * 翻訳機能が利用可能か否か(true=利用可能)
   */
  const isUseTranslation = useMemo(() => {
    if (sttStatus === STT_STATUS.READY) {
      return false; // 音声認識準備中
    }
    if (micStatus !== MIC_STATUS.SUCCESS) {
      return false; // マイク認識失敗
    }

    return true;
  }, [micStatus, sttStatus]);

  /**
   * マイク認識状況がCONNECTING以外かつ翻訳一覧が空(初期状態)であるか
   * true = 初期状態
   */
  const isInitialState = useMemo(() => {
    if (sttStatus !== STT_STATUS.CONNECTING && list.length === 0) {
      return true;
    }

    return false;
  }, [list.length, sttStatus]);

  return {
    licenseToken,
    micStatus,
    sttStatus,
    recognizedList: list,
    sttErrorType,
    setLicenseToken: changeLicenseToken,
    setMicStatus: changeMicStatus,
    setSttStatus: changeSttStatus,
    setRecognizedList: changeRecognizedList,
    addNewRecognizedItem: addRecognizedItem,
    setSttErrorType: changeSttErrorType,
    resetState,
    isUseTranslation,
    isInitialState,
  };
};
