import styles from './Spinner.module.scss';

/**
 * スピナー コンポーネント
 *
 * @param param0
 * @returns
 */
export const Spinner = () => (
  <div className={styles.spinner} data-testid="spinner" />
);
