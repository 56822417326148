import React from 'react';
import { useTranslation } from 'react-i18next';

import displaySettings from '@/assets/display_settings.svg';

import { useDisplayTypeButton } from '../../hooks/menu/useDisplayTypeButton';

type Props = {
  onCloseMenu: () => void;
};

/**
 * 表示切替メニュー
 */
export const DisplayMenuContainer = React.memo<Props>(
  ({ onCloseMenu }: Props) => {
    const { onClickButton } = useDisplayTypeButton();
    const { t } = useTranslation();

    return (
      <div>
        <button
          type="button"
          onClick={() => {
            onCloseMenu();
            onClickButton();
          }}
        >
          <img src={displaySettings} alt="displaySettings" />
          {t('mainMenu.表示切替')}
        </button>
      </div>
    );
  },
);
