import { useCallback } from 'react';

import { POCKETALK_URL } from '@/constants';

/**
 * アクセス期限切れ カスタムフック
 *
 * @returns
 */
export const useAccessExpired = () => {
  /**
   * アクセス期限切れ
   */
  const accessExpired = useCallback(() => {
    // ポケトークホームページに遷移(同タブ内)
    window.open(POCKETALK_URL, '_self');
  }, []);

  /**
   * アクセス期限切れダイアログ用OKボタンのクリック処理
   */
  const clickAccessExpiredOKButton = useCallback(() => {
    accessExpired();
  }, [accessExpired]);

  return {
    clickAccessExpiredOKButton,
  };
};
