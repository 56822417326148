import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RecognizedItem } from '@/constants';

import type { RootState } from '../store';

/**
 * マイク認識状態
 */
export const MIC_STATUS = {
  // 認識前
  NONE: 'none',
  // 認識成功
  SUCCESS: 'success',
  // 変更中
  CHANGING: 'changing',
  // 認識失敗
  ERROR: 'error',
};
export type MicStatus = (typeof MIC_STATUS)[keyof typeof MIC_STATUS];

/**
 * 音声認識(収録)状況
 */
export const STT_STATUS = {
  // 何もしていない
  INACTIVE: 'inactive',
  // 準備中
  READY: 'ready',
  // Websocketを介して音声ストリームに接続中
  CONNECTING: 'connecting',
  // 停止中
  PAUSED: 'paused',
  // エラー
  ERROR: 'error',
};
export type STTStatus = (typeof STT_STATUS)[keyof typeof STT_STATUS];

/**
 * 失敗理由
 */
export const STT_ERROR_TYPE = {
  // 無し
  NONE: 'none',
  // アクセス期限切れ
  ACCESS_EXP: 'accessExp',
  // PTID期限切れ
  PTID_EXP: 'ptidExp',
  // 利用規約が更新されている
  NEED_AGREEMENT: 'needAgreement',
  // その他エラー
  OTHER: 'other',
};
export type SttErrorType = (typeof STT_ERROR_TYPE)[keyof typeof STT_ERROR_TYPE];

/**
 * State
 */
export type SpeakerInfoState = {
  // ライセンストークン(ライセンストークン発行APIから返却された値)
  licenseToken: string;
  // マイク認識状況
  micStatus: MicStatus;
  // 音声認識(収録)状況
  sttStatus: STTStatus;
  // 翻訳画面の表示内容(翻訳リスト)
  recognizedList: EntityState<RecognizedItem>;
  // 失敗理由
  sttErrorType: SttErrorType;
};

export const recognizedListAdapter = createEntityAdapter<RecognizedItem>({
  selectId: (item) => item.id,
});

export const recognizedListAdapterSelectors =
  recognizedListAdapter.getSelectors(
    (state: RootState) => state.speakerInfo.recognizedList,
  );

/**
 * 初期State
 */
const initialState: SpeakerInfoState = {
  licenseToken: '',
  micStatus: MIC_STATUS.NONE,
  sttStatus: STT_STATUS.INACTIVE,
  recognizedList: recognizedListAdapter.getInitialState(),
  sttErrorType: STT_ERROR_TYPE.NONE,
};

/**
 * 翻訳を行うにあたっての情報を一元管理 Slice
 */
export const speakerInfoSlice = createSlice({
  name: 'speakerInfo',
  initialState,
  reducers: {
    // ライセンストークンを更新
    setLicenseToken: (state, action: PayloadAction<string>) => {
      state.licenseToken = action.payload;
    },
    // マイク認識状況を更新
    setMicStatus: (state, action: PayloadAction<MicStatus>) => {
      state.micStatus = action.payload;
    },
    // 音声認識(収録)状況を更新
    setSttStatus: (state, action: PayloadAction<STTStatus>) => {
      state.sttStatus = action.payload;
    },
    // 翻訳画面の表示内容(翻訳リスト)を更新
    setRecognizedList: (state, action: PayloadAction<RecognizedItem[]>) => {
      recognizedListAdapter.setAll(state.recognizedList, action.payload);

      return state;
    },
    // 翻訳画面の翻訳リストに翻訳結果を新規追加
    addNewRecognizedItem: (state, action: PayloadAction<RecognizedItem>) => {
      recognizedListAdapter.addOne(state.recognizedList, action.payload);

      return state;
    },
    // 失敗理由
    setSttErrorType: (state, action: PayloadAction<SttErrorType>) => {
      state.sttErrorType = action.payload;
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
