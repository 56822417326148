import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BrowserUserSettingState = {
  // 入力元デバイス
  inputDevice: string;
  // UI言語
  language: string;
};

/**
 * 初期State
 */
const initialState: BrowserUserSettingState = {
  inputDevice: '',
  language: '',
};

/**
 * [設定ダイアログ]の設定内容を管理 Slice
 */
export const browserUserSettingSlice = createSlice({
  name: 'browserUserSetting',
  initialState,
  reducers: {
    // 入力元デバイスを更新
    setInputDevice: (state, action: PayloadAction<string>) => {
      state.inputDevice = action.payload;
    },
    // UI言語を更新
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    // 初期化
    clearUserSetting: () => initialState,
  },
});
