import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ToastIconType } from '@/components/Elements';

import type { RootState } from '../store';

/**
 * 1メッセージ情報
 */
export type ToastMessageData = {
  // 一意なID
  id: string;
  // メッセージ
  message: string;
  // アイコンタイプ(非表示可)
  iconType?: ToastIconType;
};

/**
 * State
 */
export type ToastInfoState = {
  // トーストメッセージを表示中か否か(true=表示中)
  isToastVisible: boolean;
  // 最新のメッセージ
  latestMessageData: ToastMessageData | undefined;
  // トーストメッセージ格納キュー
  messageQueue: EntityState<ToastMessageData>;
};

export const messageQueueAdapter = createEntityAdapter<ToastMessageData>({
  selectId: (item) => item.id,
});

export const messageQueueAdapterSelectors = messageQueueAdapter.getSelectors(
  (state: RootState) => state.toastInfo.messageQueue,
);

/**
 * 初期State
 */
const initialState: ToastInfoState = {
  isToastVisible: false,
  latestMessageData: undefined,
  messageQueue: messageQueueAdapter.getInitialState(),
};

/**
 * トースト情報 Slice
 */
export const toastInfoSlice = createSlice({
  name: 'toastInfo',
  initialState,
  reducers: {
    // トーストメッセージの表示状況を更新
    setIsToastVisible: (state, action: PayloadAction<boolean>) => {
      state.isToastVisible = action.payload;
    },
    // 最新のトーストメッセージを更新
    setLatestMessageData: (
      state,
      action: PayloadAction<ToastMessageData | undefined>,
    ) => {
      state.latestMessageData = action.payload;
    },
    // トーストメッセージ格納キューを更新
    setMessageQueue: (state, action: PayloadAction<ToastMessageData[]>) => {
      messageQueueAdapter.setAll(state.messageQueue, action.payload);

      return state;
    },
    // トーストメッセージ格納キューにメッセージを新規追加
    addNewMessageQueue: (state, action: PayloadAction<ToastMessageData>) => {
      messageQueueAdapter.addOne(state.messageQueue, action.payload);
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
