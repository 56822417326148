import { Reducer, useCallback, useReducer } from 'react';

import { ApiStatus, API_STATUS } from '@/constants';

import { ttsApi, TTSApiResponse, TTSApiRequest } from '../ttsApi';

/**
 * State
 */
export type TtsState = {
  // レスポンスデータ
  data?: TTSApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const TTS_ACTION_TYPE = {
  FETCH_TTS: 'FETCH_TTS',
  FETCH_TTS_SUCCESS: 'FETCH_TTS_SUCCESS',
  FETCH_TTS_FAILED: 'FETCH_TTS_FAILED',
} as const;

/**
 * Action
 */
type TtsAction = {
  data?: TTSApiResponse;
  type: keyof typeof TTS_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<TtsState, TtsAction> = (
  state: TtsState,
  action: TtsAction,
) => {
  switch (action.type) {
    case TTS_ACTION_TYPE.FETCH_TTS:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };

    case TTS_ACTION_TYPE.FETCH_TTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };

    case TTS_ACTION_TYPE.FETCH_TTS_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };
    default:
      return state;
  }
};

/**
 * TTS API 呼び出しhooks
 *
 * @returns
 */
export const useTtsApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const fetchTts = useCallback(
    (request: TTSApiRequest) => {
      if (state.status === API_STATUS.LOADING) {
        return;
      }

      dispatch({ type: TTS_ACTION_TYPE.FETCH_TTS });

      ttsApi(request)
        .then((response: TTSApiResponse) => {
          // 成功
          if (response.audioContent) {
            dispatch({
              type: TTS_ACTION_TYPE.FETCH_TTS_SUCCESS,
              data: response,
            });

            return;
          }

          // 失敗
          dispatch({
            type: TTS_ACTION_TYPE.FETCH_TTS_FAILED,
            data: response,
          });
        })
        .catch((_) => {
          dispatch({
            type: TTS_ACTION_TYPE.FETCH_TTS_FAILED,
          });
        });
    },
    [state.status],
  );

  return {
    ttsState: state,
    fetchTts,
  };
};
