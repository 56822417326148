import React from 'react';

import { useInterimListScroll } from '../../hooks/useInterimListScroll';

import styles from './ListView.module.scss';

/**
 * プロパティ
 */
export type InterimListView = {
  // 暫定テキスト
  text: string;
};

/**
 * 暫定テキストビュー コンポーネント
 */
export const InterimListView = React.memo(({ text }: InterimListView) => {
  const autoScroll = useInterimListScroll();

  return (
    <ul className={styles.interim} data-testid="interim-list-view">
      <li>
        {text && (
          <div className={styles.raw} ref={autoScroll.interimListRef}>
            {text}
          </div>
        )}
      </li>
    </ul>
  );
});
