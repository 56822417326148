import { Unsubscribe } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';

import { RecognizedText } from '@/constants';
import {
  SESSION_INFO_API_RESULT_CODE,
  useLangAddApi,
  useSessionInfoApi,
} from '@/features/api';
import { useTextToSpeech } from '@/features/texttospeech';
import { useLanguage } from '@/hooks/useLanguage';

import { useFirestore } from './useFirestore';

/**
 * オーディエンス画面 hooks
 *
 * @returns
 */
export const useAudience = ({ token }: { token: string }) => {
  const { subcscribeTextData, interimTextData, recognizedTextData, isOnair } =
    useFirestore();

  const { addTtsList } = useTextToSpeech();
  const [recognizedTexts, setRecognizedTexts] =
    useState<RecognizedText[]>(recognizedTextData);
  const { destlang } = useLanguage();
  const { addDestlang } = useLangAddApi();

  const { sessionInfoState, fetchSessionInfo } = useSessionInfoApi();

  // 確定テキストが更新された時
  useEffect(() => {
    setRecognizedTexts(recognizedTextData);
  }, [recognizedTextData]);

  // 確定テキストデータが追加されたとき、TTSを再生する
  const latestTextIdRef = useRef<number>();
  useEffect(() => {
    // 確定テキストデータがない場合は処理を終了する
    if (!recognizedTexts || recognizedTexts.length <= 0) {
      return;
    }

    // 末尾の確定テキストデータ
    const latestRecognizedTexts = recognizedTexts.slice(-1)[0];

    // 新規のデータがない場合は処理を終了する
    if (latestTextIdRef.current === latestRecognizedTexts.id) {
      return;
    }

    addTtsList(
      latestRecognizedTexts.value.ttt,
      latestRecognizedTexts.value.destlang,
    );
    latestTextIdRef.current = latestRecognizedTexts.id;
  }, [addTtsList, recognizedTexts]);

  // セッション情報取得APIが実行された時
  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    if (sessionInfoState.data?.resultCode === SESSION_INFO_API_RESULT_CODE.OK) {
      unsubscribe = subcscribeTextData(
        sessionInfoState.data.firestoreSessionId,
      );
    }

    // TODO: セッション情報が取得できない場合はエラー表示

    return () => {
      unsubscribe?.();
    };
  }, [sessionInfoState, subcscribeTextData]);

  // 初回レンダリング時
  useEffect(() => {
    fetchSessionInfo({ token });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 翻訳先言語を変更した時
  useEffect(() => {
    if (!destlang) {
      return;
    }

    // 翻訳先言語追加APIを呼び出す
    addDestlang({ destlang }, token);

    // 翻訳先言語の変更時のみ実行させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destlang]);

  return {
    interimText: interimTextData,
    recognizedTexts,
    isOnair,
  };
};
