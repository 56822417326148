import { useCallback, useState } from 'react';

import { isSupportBrowser } from '@/utils/browser';

/**
 * 非対応ブラウザ警告ダイアログ カスタムフック
 */
export const useUnSupportBrowserDialog = () => {
  const [isUnSupportBrowserDialogOpen, setIsUnSupportBrowserDialogOpen] =
    useState<boolean>(!isSupportBrowser());

  /**
   * 非対応ブラウザアラートのOKボタン押下時の処理
   */
  const clickUnSupportBrowserOKButton = useCallback(() => {
    setIsUnSupportBrowserDialogOpen(false);
  }, []);

  return { isUnSupportBrowserDialogOpen, clickUnSupportBrowserOKButton };
};
