import { useCallback, useEffect, useRef } from 'react';

import { CONFERENCE } from '@/constants';
import {
  useStreamApi,
  StreamApiResponse,
  licenseCreateApi,
  createApi,
  LICENSE_CREATE_API_RESULT_CODE,
  CREATE_API_RESULT_CODE,
  CLOSE_EVENT_TYPE,
  CloseEventType,
  XOptionValue,
  useLangAddApi,
} from '@/features/api';
import { useLanguage } from '@/hooks/useLanguage';
import { useSpeakerInfo } from '@/hooks/useSpeakerInfo';
import {
  STTStatus,
  STT_ERROR_TYPE,
  STT_STATUS,
  SttErrorType,
} from '@/states/slices/speakerInfoSlice';
import { checkLicenseTokenExp } from '@/utils';

import { convertCloseEventToSttError } from '../utils/closeEventConverter';

/**
 * 本カスタムフックからの返却値
 */
export type UseVoiceInputValue = {
  // 音声認識開始
  requestStartStream: () => void;
  // 音声ストリームAPIにメッセージ送信
  sendAudio: (buffer: any) => void;
  // 音声ストリームAPIのリクエスト停止
  sendFinal: () => void;
  // 音声認識終了
  closeStream: () => void;
  // 音声認識破棄
  discard: () => void;
};

/**
 * プロパティ
 */
export type VoiceInputProps = {
  // セッション情報作成時に発行したトークン
  token: string;
  // Websocket接続中に音声ストリームAPIから返却された結果を受け取る
  onResult: (response: StreamApiResponse) => void;
  // スクリプトプロセッサ開始
  startAudioContext: () => void;
  // スクリプトプロセッサ停止
  stopAudioContext: () => void;
  // 音声ストリームAPIに設定したノイズキャンセリングに指定したい値
  noiseValue: XOptionValue;
};

/**
 * 音声入力 hooks
 *
 * @param param0
 * @returns
 */
export const useVoiceInput = ({
  token,
  onResult,
  startAudioContext,
  stopAudioContext,
  noiseValue,
}: VoiceInputProps): UseVoiceInputValue => {
  const { openWebSocket, sendAudio, sendFinal, closeWebSocket } =
    useStreamApi();
  const { addDestlang } = useLangAddApi();
  const {
    licenseToken,
    sttStatus,
    sttErrorType,
    setLicenseToken,
    setSttStatus,
    setSttErrorType,
    resetState,
  } = useSpeakerInfo();
  // Reduxに保存された翻訳先言語/翻訳元言語
  const { srclang, destlang } = useLanguage();
  // ストリームID発行APIから取得したアクセスキー
  const accesskey = useRef<string>('');

  /**
   * ライセンストークンの変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const licenseTokenRef = useRef<string>(licenseToken);
  useEffect(() => {
    licenseTokenRef.current = licenseToken;
  }, [licenseToken]);

  /**
   * STT状態を監視してsttStatusRef(useRef)を更新
   *
   * イベントリスナー内のuseStateやReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const sttStatusRef = useRef<STTStatus>(sttStatus);
  useEffect(() => {
    sttStatusRef.current = sttStatus;
  }, [sttStatus]);

  /**
   * ライセンストークンとアクセスキーの両方が揃っているか確認
   *
   * @returns true=両方揃っている
   */
  const hasAuthInfo = useCallback((): boolean => {
    if (!licenseTokenRef.current) {
      return false;
    }
    if (!accesskey.current) {
      return false;
    }

    return true;
  }, []);

  /**
   * ライセンストークンとアクセスキーを削除
   */
  const resetAuthInfo = useCallback(() => {
    setLicenseToken('');
    accesskey.current = '';
  }, [setLicenseToken]);

  /**
   * 以下のAPIを呼んで音声認識の準備を行う
   * ・ライセンストークン発行API
   * ・ストリームID発行API
   *
   * @returns エラー理由
   */
  const readyStream = useCallback(async (): Promise<SttErrorType> => {
    try {
      // ライセンストークン発行API呼び出し
      const licenseCreateApiResponse = await licenseCreateApi(token);
      switch (licenseCreateApiResponse.resultCode) {
        case LICENSE_CREATE_API_RESULT_CODE.OK:
          break; // 後述の処理継続
        case LICENSE_CREATE_API_RESULT_CODE.WARN_AUTH:
          return STT_ERROR_TYPE.ACCESS_EXP; // アクセス期限切れ
        case LICENSE_CREATE_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN:
          return STT_ERROR_TYPE.PTID_EXP; // PTID期限切れ
        case LICENSE_CREATE_API_RESULT_CODE.INFO_NEED_AGREEMENT:
          return STT_ERROR_TYPE.NEED_AGREEMENT; // 利用規約が更新されている
        default:
          return STT_ERROR_TYPE.OTHER;
      }

      // ライセンストークン発行APIから返却されたライセンストークンをReduxに保存
      const responseLicenseToken = licenseCreateApiResponse.token;
      setLicenseToken(responseLicenseToken);

      // ストリームID発行API呼び出し
      const streamCreateApiResponse = await createApi(
        {
          licenseToken: responseLicenseToken,
          codec: CONFERENCE.CODEC,
          srclang,
          destlang,
        },
        token,
      );
      if (streamCreateApiResponse.resultCode !== CREATE_API_RESULT_CODE.OK) {
        return STT_ERROR_TYPE.OTHER;
      }
      accesskey.current = streamCreateApiResponse.accessKey;

      return STT_ERROR_TYPE.NONE;
    } catch {
      return STT_ERROR_TYPE.OTHER;
    }
  }, [destlang, setLicenseToken, srclang, token]);

  /**
   * STTの状態を「その他エラー」にする
   */
  const changeSttStatusToOtherError = useCallback(() => {
    setSttStatus(STT_STATUS.ERROR);
    setSttErrorType(STT_ERROR_TYPE.OTHER);
  }, [setSttErrorType, setSttStatus]);

  /**
   * Websocketがクローズされた場合の処理
   */
  const websocketClose = useCallback(
    (closeEventType: CloseEventType) => {
      if (sttStatusRef.current !== STT_STATUS.CONNECTING) {
        return;
      }

      // 正常に切断された場合は何もしない
      if (closeEventType === CLOSE_EVENT_TYPE.SUCCESS) {
        return;
      }

      // その他エラーダイアログを表示
      changeSttStatusToOtherError();
    },
    [changeSttStatusToOtherError],
  );

  /**
   * 以下の処理を行ってWebsocketを介して音声ストリームに接続する
   * ・Websocketを介して音声ストリームに接続
   * ・スクリプトプロセッサ開始
   *
   * @returns エラー理由
   */
  const connectStream = useCallback(async (): Promise<SttErrorType> => {
    try {
      // Websocketを介して音声ストリームに接続
      // 音声ストリームAPIリクエスト開始
      const result = await openWebSocket(
        {
          accessKey: accesskey.current,
          autoDetect: true,
          licenseToken: licenseTokenRef.current,
          noiseSuppression: noiseValue,
          sessionToken: token,
        },
        onResult,
        websocketClose,
      );
      if (!result.closeEventType) {
        // Websocket接続成功
        setSttStatus(STT_STATUS.CONNECTING);
        // スクリプトプロセッサ開始
        startAudioContext();

        return STT_ERROR_TYPE.NONE;
      }

      // Websocket接続失敗
      return convertCloseEventToSttError(result.closeEventType);
    } catch {
      return STT_ERROR_TYPE.OTHER;
    }
  }, [
    noiseValue,
    onResult,
    openWebSocket,
    setSttStatus,
    startAudioContext,
    token,
    websocketClose,
  ]);

  /**
   * 開始処理
   *
   * @returns エラー理由
   */
  const startStream = useCallback(async (): Promise<SttErrorType> => {
    // 音声認識状態を「準備中」に変更
    setSttStatus(STT_STATUS.READY);

    // ライセンストークン・アクセスキー未取得 or ライセンストークンが期限切れ
    if (!hasAuthInfo() || !checkLicenseTokenExp(licenseTokenRef.current)) {
      // ライセンストークンとアクセスキーを削除
      resetAuthInfo();
      // ライセンストークン発行API、ストリームID発行API
      const readyResult = await readyStream();
      if (readyResult !== STT_ERROR_TYPE.NONE) {
        return readyResult;
      }
    }

    // Websocketを介して音声ストリームに接続
    const connectResult = await connectStream();
    if (connectResult === STT_ERROR_TYPE.OTHER) {
      // ネットワーク接続エラーの場合はリトライせずにリトライエラーダイアログを表示して終了
      changeSttStatusToOtherError();

      return STT_ERROR_TYPE.NONE;
    }

    return connectResult;
  }, [
    changeSttStatusToOtherError,
    connectStream,
    hasAuthInfo,
    readyStream,
    resetAuthInfo,
    setSttStatus,
  ]);

  /**
   * 音声認識開始
   *
   * @returns
   */
  const requestStartStream = useCallback(async () => {
    // 音声認識状態が「停止中」か確認
    if (
      sttStatusRef.current !== STT_STATUS.PAUSED &&
      sttStatusRef.current !== STT_STATUS.INACTIVE
    ) {
      return;
    }

    const result = await startStream();
    if (result !== STT_ERROR_TYPE.NONE) {
      setSttStatus(STT_STATUS.ERROR);
      setSttErrorType(result);
    }
  }, [setSttErrorType, setSttStatus, startStream]);

  /**
   * 音声認識停止
   *
   * @returns
   */
  const closeStream = useCallback(async () => {
    // Reduxで管理している「音声認識状態」が「Websocketを介して音声ストリームに接続中」か
    if (sttStatus !== STT_STATUS.CONNECTING) {
      // 何もしない
      return;
    }

    // スクリプトプロセッサ停止
    stopAudioContext();
    // Websocket閉じる
    closeWebSocket();
    // Reduxで管理している「音声認識状態」を「停止中」にする
    setSttStatus(STT_STATUS.PAUSED);
  }, [closeWebSocket, setSttStatus, stopAudioContext, sttStatus]);

  /**
   * 翻訳先言語を追加
   * @returns
   */
  const addLanguage = useCallback(async () => {
    // Websocket接続中以外は何もしない
    if (sttStatus !== STT_STATUS.CONNECTING) {
      return;
    }

    // 翻訳先言語追加APIを呼び出す
    addDestlang({ destlang }, token);
  }, [addDestlang, destlang, sttStatus, token]);

  /**
   * 翻訳先言語が変更されたときの処理
   */
  useEffect(() => {
    addLanguage();

    // addLanguageを監視対象外としたいため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destlang]);

  /**
   * 失敗理由が変更されたときの処理
   */
  useEffect(() => {
    if (sttErrorType !== STT_ERROR_TYPE.NONE) {
      // スクリプトプロセッサ停止
      stopAudioContext();
      // Websocket閉じる
      closeWebSocket();
    }
  }, [closeWebSocket, stopAudioContext, sttErrorType]);

  /**
   * 音声認識破棄
   */
  const discard = () => {
    // スクリプトプロセッサ停止
    stopAudioContext();
    // Websocket閉じる
    closeWebSocket();
    // Reduxに保存した「翻訳情報」をすべてリセット
    resetState();
  };

  /**
   * マウント時、アンマウント時の処理
   */
  useEffect(() => {
    resetState();

    // アンマウント時はWebSocketを切断し、Reduxに保管していた翻訳関連の情報をリセット
    return () => {
      discard();
    };

    // コンポーネントのマウント/アンマウント時に1度だけ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    requestStartStream,
    sendAudio,
    sendFinal,
    closeStream,
    discard,
  };
};
