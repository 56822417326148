import React, { useRef } from 'react';

import fontSize from '@/assets/font-size.svg';
import {
  InputSlider,
  POPUP_CONTENT_ALIGN_POSITION,
  PopupContents,
  PopupDetails,
  PopupSummary,
} from '@/components/Elements';
import { FONT_SIZE_INFO } from '@/constants';

import { useFontSizeInput } from '../hooks/useFontSizeInput';

import styles from './Header.module.scss';

/**
 * 文字サイズ変更
 */
export const FontSizeContainer = React.memo(() => {
  // メニュークリックでopen要素削除する
  const detailsRef = useRef<HTMLDetailsElement | null>(null);
  const { onChangeFontSize } = useFontSizeInput();

  return (
    <div>
      <PopupDetails detailsRef={detailsRef}>
        <PopupSummary>
          <div className={styles.menu}>
            <img src={fontSize} alt="font-size" />
          </div>
        </PopupSummary>
        <PopupContents align={POPUP_CONTENT_ALIGN_POSITION.RIGHT}>
          <div
            className={`${styles['menu-contents']} ${styles['font-size-slider']}`}
          >
            <ul>
              <li>
                <InputSlider
                  min={FONT_SIZE_INFO.minLength}
                  max={FONT_SIZE_INFO.maxLength}
                  step={FONT_SIZE_INFO.step}
                  defaultValue={FONT_SIZE_INFO.defaultValue}
                  onChange={onChangeFontSize}
                />
              </li>
            </ul>
          </div>
        </PopupContents>
      </PopupDetails>
    </div>
  );
});
