import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { ToastIconType } from '@/components/Elements';
import {
  toastInfoSlice,
  messageQueueAdapterSelectors,
  ToastInfoState,
  ToastMessageData,
} from '@/states/slices/toastInfoSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * トーストに関する情報を保存 hooks
 *
 * @returns
 */
export const useToastInfo = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const {
    setIsToastVisible,
    setLatestMessageData,
    setMessageQueue,
    addNewMessageQueue,
    resetToInitialState,
  } = toastInfoSlice.actions;

  const { isToastVisible, latestMessageData } = useSelector<
    RootState,
    ToastInfoState
  >((state) => state.toastInfo);

  // トーストメッセージ格納キュー
  const messageQueue = useSelector(messageQueueAdapterSelectors.selectAll);

  /**
   * トーストメッセージの表示状態を更新
   */
  const changeIsToastVisible = useCallback(
    (value: boolean) => {
      dispatch(setIsToastVisible(value));
    },
    [dispatch, setIsToastVisible],
  );

  /**
   * 最新のメッセージを更新
   */
  const changeLatestMessageData = useCallback(
    (value: ToastMessageData | undefined) => {
      dispatch(setLatestMessageData(value));
    },
    [dispatch, setLatestMessageData],
  );

  /**
   * トーストメッセージ格納キューを更新
   */
  const changeMessageQueue = useCallback(
    (value: ToastMessageData[]) => {
      dispatch(setMessageQueue(value));
    },
    [dispatch, setMessageQueue],
  );

  /**
   * トーストメッセージ格納キューにメッセージを追加
   */
  const addMessageQueue = useCallback(
    (value: ToastMessageData) => {
      dispatch(addNewMessageQueue(value));
    },
    [addNewMessageQueue, dispatch],
  );

  /**
   * トーストメッセージを追加
   * ※一意なIDが必ず指定されるようにこの関数だけpublicにする
   *
   * @param newMessage トーストに表示したいメッセージ
   * @param iconType アイコンタイプ(未指定の場合は非表示)
   */
  const addToastMessage = useCallback(
    (newMessage: string, iconType?: ToastIconType) => {
      addMessageQueue({
        id: uuidv4(),
        message: newMessage,
        iconType,
      });
    },
    [addMessageQueue],
  );

  /**
   * 未表示のトーストメッセージが残っているか否か(true=残っている)
   */
  const hasMessage = useMemo(() => {
    if (!messageQueue) {
      return false;
    }

    return messageQueue.length > 0;
  }, [messageQueue]);

  /**
   * キューからメッセージを取り出す
   */
  const shiftMessageQueue = useCallback(() => {
    const message = messageQueue.shift();
    changeMessageQueue(messageQueue);

    return message;
  }, [changeMessageQueue, messageQueue]);

  /**
   * キューの中身を全て破棄
   */
  const clearMessageQueue = useCallback(() => {
    changeMessageQueue([]);
  }, [changeMessageQueue]);

  /**
   * トーストに関する全ての情報をリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  return {
    isToastVisible,
    latestMessageData,
    setIsToastVisible: changeIsToastVisible,
    setLatestMessageData: changeLatestMessageData,
    addToastMessage,
    hasMessage,
    shiftMessageQueue,
    clearMessageQueue,
    resetState,
  };
};
