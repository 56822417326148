import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  DialogMessageContents,
  ModalDialog,
  SpinnerOverlayView,
} from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { FONT_SIZE_INFO, LIST_DISPLAY_TYPE, URL_PARAMS_KEY } from '@/constants';
import { AccessExpiredDialog } from '@/features/expired';
import { useSpeakerInfo } from '@/hooks/useSpeakerInfo';
import {
  MIC_STATUS,
  STT_ERROR_TYPE,
} from '@/states/slices/translationInfoSlice';

import { useSpeaker } from '../../hooks/speaker/useSpeaker';
import { OtherErrorDialog } from '../error/OtherErrorDialog';
import { SpeakerFooter } from '../footer/SpeakerFooter';
import { TranslationListView } from '../list/TranslationListView';

/**
 * スピーカー画面 コンポーネント
 *
 * @returns
 */
export const Speaker = React.memo(() => {
  const { token: tokenParam } = useParams<{
    [URL_PARAMS_KEY.SPEAKER.token]: string;
  }>();
  const { interimText, clickSttButton, isVisibledSpinner } = useSpeaker({
    token: tokenParam || '',
  });
  const { micStatus, recognizedList, isInitialState, sttErrorType } =
    useSpeakerInfo();
  const { t } = useTranslation();

  return (
    <>
      <SpinnerOverlayView isOpen={isVisibledSpinner} />
      <ModalDialog
        isOpen={micStatus === MIC_STATUS.ERROR}
        title={t('translationDialog.通訳機能が利用できません').toString()}
      >
        <DialogMessageContents>
          {t('translationDialog.マイクを認識できませんでした。')}
        </DialogMessageContents>
      </ModalDialog>
      <div>
        {/* その他エラーダイアログを表示 */}
        <OtherErrorDialog isOpen={sttErrorType === STT_ERROR_TYPE.OTHER} />
        {/* アクセス期限切れダイアログを表示 */}
        <AccessExpiredDialog
          isOpen={sttErrorType === STT_ERROR_TYPE.ACCESS_EXP}
        />

        <ContentLayout
          footerChildren={<SpeakerFooter onClickSttButton={clickSttButton} />}
        >
          <TranslationListView
            interimText={interimText}
            recognizedList={recognizedList}
            displayType={LIST_DISPLAY_TYPE.list}
            fontSize={FONT_SIZE_INFO.defaultValue}
            point={FONT_SIZE_INFO.point}
            isInitialState={isInitialState}
          />
        </ContentLayout>
      </div>
    </>
  );
});
