/**
 * 指定されたBase64をUint8Arrayに変換
 *
 * @param base64Str
 * @returns
 */
const convertBase64ToUint8Array = (base64Str: string) => {
  const raw = atob(base64Str);

  const buffer = new Uint8Array(raw.length);
  for (let i = 0; i < raw.length; i += 1) {
    buffer[i] = raw.charCodeAt(i);
  }

  return buffer;
};

/**
 * 音声データ(audio/wav)を作成する
 *
 * @param buffer
 * @returns
 */
const createWavFile = (buffer: ArrayBuffer) =>
  window.URL.createObjectURL(new Blob([buffer], { type: 'audio/wav' }));

/**
 * 指定された文字列を音声データ(audio/wav)に変換
 *
 * @param targetStr Google TTSから返却された文字列
 * @returns
 */
export const convertToWavFileFromGoogleTts = async (targetStr: string) => {
  // base64の音声データをデコードする
  const decodeData = convertBase64ToUint8Array(targetStr.replace(/^.*,/, ''));

  return createWavFile(decodeData.buffer);
};

/**
 * 音声データ(audio/wav)を解放
 *
 * ※createObjectURLで作成したファイルは必ずrevokeObjectURLしてあげないとメモリリークする
 *
 * @param blobUrl 解放対象のファイルURL
 */
export const removeWavFile = (blobUrl: string) => {
  window.URL.revokeObjectURL(blobUrl);
};
