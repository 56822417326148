// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';

import {
  ListDisplayType,
  LIST_DISPLAY_TYPE,
  RecognizedText,
  FEEDBACK_LINK_URL,
} from '@/constants';

import { InterimListView } from './InterimListView';
import styles from './ListView.module.scss';
import { RecognizedListView } from './RecognizedListView';

/**
 * プロパティ
 */
export type AudienceListView = {
  // 暫定テキスト
  interimText: string;
  // 音声認識結果/翻訳結果テキストリストの表示内容
  recognizedList: RecognizedText[];
  // 表示タイプ
  displayType: ListDisplayType;
  // フォントサイズ
  fontSize?: number;
  // 文字サイズ単位
  point?: string;
  // フッター開閉状況
  isClose?: boolean;
};

/**
 * 暫定テキストビュー
 */
const InterimView = React.memo(({ text }: { text: string }) => (
  <InterimListView text={text} />
));

/**
 * 翻訳テキストビュー
 */
const RecognizedView = React.memo(
  ({
    lists,
    displayType,
  }: {
    lists: RecognizedText[];
    displayType: ListDisplayType;
  }) => <RecognizedListView lists={lists} displayType={displayType} />,
);

/**
 * オーディエンス画面リストビュー コンポーネント
 *
 * @param param0
 * @returns
 */
export const AudienceListView = React.memo(
  ({
    interimText,
    recognizedList,
    displayType,
    fontSize,
    point = 'rem',
    isClose,
  }: AudienceListView) => (
    <div
      className={`${styles.content} ${
        isClose ? styles['footer-close'] : styles['footer-open']
      }`}
    >
      <div
        className={`${styles.text} ${styles.conference}`}
        style={fontSize ? { fontSize: `${fontSize}${point}` } : undefined}
      >
        {displayType === LIST_DISPLAY_TYPE.onePhrase ? (
          <div className={styles['one-phrase']} id="onePhrase">
            <RecognizedView lists={recognizedList} displayType={displayType} />
            <InterimView text={interimText} />
          </div>
        ) : (
          <div className={styles.list} id="list">
            <RecognizedView lists={recognizedList} displayType={displayType} />
            <InterimView text={interimText} />
          </div>
        )}
      </div>
    </div>
  ),
);

/**
 * オフエア時のオーディエンス画面ビュー
 */
export const AudienceOffairView = React.memo(() => (
  <div className={styles.content}>
    <div className={`${styles.text} ${styles.conference}`}>
      <div>現在は同時通訳していません。</div>
      <div>
        ポケトーク同時通訳 forカンファレンスに対するフィードバックはこちら
      </div>
      <div>
        <a href={FEEDBACK_LINK_URL}>{FEEDBACK_LINK_URL}</a>
      </div>
    </div>
  </div>
));
