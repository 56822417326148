import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ListDisplayType } from '@/constants';
import {
  translationDisplaySlice,
  TranslationDisplayState,
} from '@/states/slices/translationDisplaySlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * 翻訳画面の表示に関する情報を保存 hooks
 *
 * @returns
 */
export const useTranslationDisplay = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setDisplayType, setFontSize, resetToInitialState } =
    translationDisplaySlice.actions;

  const { displayType, fontSize } = useSelector<
    RootState,
    TranslationDisplayState
  >((state) => state.translationDisplay);

  /**
   * 翻訳元テキストの表示/非表示を更新
   */
  const changeDisplayType = useCallback(
    (value: ListDisplayType) => {
      dispatch(setDisplayType(value));
    },
    [dispatch, setDisplayType],
  );

  /**
   * 翻訳関連テキストの文字サイズを更新
   */
  const changeFontSize = useCallback(
    (value: number) => {
      dispatch(setFontSize(value));
    },
    [dispatch, setFontSize],
  );

  /**
   * 全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  return {
    displayType,
    fontSize,
    setDisplayType: changeDisplayType,
    setFontSize: changeFontSize,
    resetState,
  };
};
