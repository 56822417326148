/**
 * タブを閉じる、ブラウザを閉じる、リロードイベントを検知
 *
 * @param event
 */
const handleBeforeunload = (event: BeforeUnloadEvent): void => {
  event.preventDefault();
  event.returnValue = '';
};

/**
 * タブを閉じる、ブラウザを閉じる、リロードイベントを登録
 */
export const addBeforeunloadEvent = () => {
  window.addEventListener('beforeunload', handleBeforeunload);
};

/**
 * タブを閉じる、ブラウザを閉じる、リロードイベントを削除
 */
export const removeBeforeunloadEvent = () => {
  window.removeEventListener('beforeunload', handleBeforeunload);
};
