/**
 * スネークケースをキャメルケースに変更する
 * 参考：https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
 *
 * @param str スネークケースをキャメルケースに変更する
 * @returns スネークケースをキャメルケースに変換したもの
 */
export const convertSnakeCaseToCamelCase = (
  str: string | undefined,
): string => {
  if (str === undefined) {
    return '';
  }
  const camelCaseStr = str.replace(/_./g, (word) => word[1].toUpperCase());

  return camelCaseStr;
};

/**
 * キャメルケースをスネークケースに変更する
 *
 * @param str キャメルケースをスネークケースに変更する
 * @returns キャメルケースをスネークケースに変換したもの
 */
export const convertCamelCaseToSnakeCase = (
  str: string | undefined,
): string => {
  if (str === undefined) {
    return '';
  }

  const snakeCaseStr = str.replaceAll(
    /.[A-Z]/g,
    (word) => `${word[0]}_${word[1].toLowerCase()}`,
  );

  return snakeCaseStr;
};
