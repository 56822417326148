import { CONFERENCE } from '@/constants';
import { conferenceAxios } from '@/lib/axios';
import { convertObjectToSneakJson } from '@/utils';

import { API_AUTH_RESULT_CODE, createAuthHeader } from './apiAuth';

/**
 * リクエストパラメータ
 */
export type CreateApiRequest = {
  // ライセンストークン
  licenseToken: string;
  // CODEC
  codec: string;
  // 音声認識言語
  srclang: string;
  // 翻訳先言語
  destlang: string;
};

/**
 * 結果コード
 */
export const CREATE_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 外部API呼び出し失敗
  WARN_FAILED_CALL: 'WARN_FAILED_CALL',
  // 許可されてないトークンで利用された場合
  WARN_NOT_ALLOWED: 'WARN_NOT_ALLOWED',
  /// 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',

  ...API_AUTH_RESULT_CODE,
} as const;
export type CreateApiResultCode =
  (typeof CREATE_API_RESULT_CODE)[keyof typeof CREATE_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isCreateApiResultCode = (
  resultCode: string,
): resultCode is CreateApiResultCode => {
  if (resultCode in CREATE_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type CreateApiResponse = {
  // アクセスキー
  accessKey: string;
  // 結果コード
  resultCode: CreateApiResultCode;
};

/**
 * API 呼び出し
 *
 * @param request
 * @returns
 */
const callApi = (
  request: CreateApiRequest,
  token: string,
): Promise<CreateApiResponse> => {
  // パラメータをキャメルケースのJSONに変換
  const params = convertObjectToSneakJson(request);

  // リクエストヘッダー
  const reqHeaders = {
    'Content-Type': 'application/json',
    ...createAuthHeader(token),
  };

  // リクエスト送信(POST)
  const response = conferenceAxios
    .post<CreateApiResponse>(CONFERENCE.URL.CREATE, params, {
      headers: reqHeaders,
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * ストリームID発行API リクエスト
 *
 * @param request
 * @returns
 */
export const createApi = (
  request: CreateApiRequest,
  token: string,
): Promise<CreateApiResponse> => callApi(request, token);
