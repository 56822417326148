import { currentUnixTime } from '../date';

/**
 * JWT形式の文字列をパースしてペイロードを取得
 *
 * @param token
 * @returns
 */
const parseJwt = (token: string) => {
  const tokenArray = token.split('.');
  if (tokenArray.length < 2) {
    return '';
  }
  const base64Url = tokenArray[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

/**
 * ライセンストークンの期限をチェック
 * ※ライセンストークン=エンジンサーバで利用する通行手形
 *
 * @param targetLicenseToken チェック対象のライセンストークン
 * @returns true=OK/false=NG
 */
export const checkLicenseTokenExp = (targetLicenseToken: string): boolean => {
  if (!targetLicenseToken) {
    return false;
  }

  // 期限チェック
  const payload = parseJwt(targetLicenseToken);
  if (!payload) {
    return false;
  }
  const { exp } = payload;

  if (exp < currentUnixTime()) {
    return false;
  }

  return true;
};
