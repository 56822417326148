import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { audioInfoSlice, AudioInfoState } from '@/states/slices/audioInfoSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * Audioに関する情報を保存 hooks
 *
 * @returns
 */
export const useAudioInfo = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setIsAudioPlay, resetToInitialState } = audioInfoSlice.actions;

  const { isAudioPlay } = useSelector<RootState, AudioInfoState>(
    (state) => state.audioInfo,
  );

  /**
   * Audioが再生状況を更新
   */
  const changeIsAudioPlay = useCallback(
    (value: boolean) => {
      dispatch(setIsAudioPlay(value));
    },
    [dispatch, setIsAudioPlay],
  );

  /**
   * Audioに関する全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  return {
    isAudioPlay,
    setIsAudioPlay: changeIsAudioPlay,
    resetState,
  };
};
