import { useCallback, useEffect } from 'react';

import { useToastInfo } from '@/hooks/useToastInfo';

/**
 * 本カスタムフックからの返却値
 */
export type Value = {
  // メッセージ終了後に実行する処理
  visibleEnd: () => void;
  // クローズ処理
  onClose: () => void;
};

/**
 * トーストメッセージ表示を管理するためのカスタムフック
 *
 * ※メッセージが複数存在する場合は、表示中のメッセージが消えてから次のメッセージを表示する
 *
 * @returns
 */
export const useToast = (): Value => {
  const {
    isToastVisible,
    setIsToastVisible,
    setLatestMessageData,
    hasMessage,
    shiftMessageQueue,
    resetState,
  } = useToastInfo();

  /**
   * クローズ処理
   */
  const onClose = useCallback(() => {
    setIsToastVisible(false);
    setLatestMessageData(undefined);
  }, [setIsToastVisible, setLatestMessageData]);

  /**
   * 表示終了時の処理
   */
  const visibleEnd = useCallback(() => {
    onClose();
  }, [onClose]);

  /**
   * 表示管理
   */
  useEffect(() => {
    if (!hasMessage) {
      return; // メッセージがない場合は何もしない
    }

    if (isToastVisible) {
      return; // すでにメッセージを表示中の場合は何もしない
    }

    // キューから最新のメッセージを取り出して表示
    const messageData = shiftMessageQueue();
    if (messageData) {
      setLatestMessageData(messageData);
      setIsToastVisible(true);
    }
  }, [
    hasMessage,
    shiftMessageQueue,
    isToastVisible,
    setIsToastVisible,
    setLatestMessageData,
  ]);

  /**
   * マウント/アンマウント時に実行する処理
   */
  useEffect(
    // アンマウント時の処理
    () => () => {
      resetState();
    },
    // コンポーネントのアンマウント時に1度だけ実行
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    visibleEnd,
    onClose,
  };
};
