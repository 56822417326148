/**
 * デバイスの種類
 */
export const DEVICE_TYPE = {
  // iPhone
  IPHONE: 'iPhone',
  // iPad
  IPAD: 'iPad',
  // Android
  ANDROID: 'Android',
  // Androidタブレット
  ANDROID_TABLET: 'Android_tablet',
  // その他タブレット
  OTHER_TABLET: 'Other_tablet',
  // PC
  PC: 'PC',
};
export type DeviceType = (typeof DEVICE_TYPE)[keyof typeof DEVICE_TYPE];

/**
 * ユーザーエージェントから利用しているデバイスを判定する
 *
 * @returns デバイスの種類
 */
export const detectDevice = (): DeviceType => {
  const { userAgent } = window.navigator;

  // iPhone判定
  if (/iPhone/.test(userAgent)) {
    return DEVICE_TYPE.IPHONE;
  }

  // iPad判定
  if (/iPad|Macintosh/.test(userAgent) && 'ontouchend' in document) {
    return DEVICE_TYPE.IPAD;
  }

  // Android判定
  if (/Android/.test(userAgent) && /Mobile/.test(userAgent)) {
    return DEVICE_TYPE.ANDROID;
  }

  // Androidタブレット判定
  if (/Android/.test(userAgent) && !/Mobile/.test(userAgent)) {
    return DEVICE_TYPE.ANDROID_TABLET;
  }

  // その他タブレット判定
  if ('ontouchend' in document) {
    return DEVICE_TYPE.OTHER_TABLET;
  }

  return DEVICE_TYPE.PC;
};

/**
 * 利用しているデバイスがPCかモバイルかを判定する
 *
 * @returns
 */
export const isMobile = (): boolean => {
  const device = detectDevice();

  switch (device) {
    case DEVICE_TYPE.IPHONE:
    case DEVICE_TYPE.IPAD:
    case DEVICE_TYPE.ANDROID:
    case DEVICE_TYPE.ANDROID_TABLET:
    case DEVICE_TYPE.OTHER_TABLET:
      return true;
    default:
      return false;
  }
};
