import { useCallback, useEffect, useRef } from 'react';

export const useInterimListScroll = () => {
  const interimListRef = useRef<HTMLDivElement>(null);

  /**
   * 暫定テキストのリストビュー スクロール制御
   */
  const interimListScrollView = useCallback(() => {
    if (interimListRef.current === null) {
      return;
    }

    const left = interimListRef.current.scrollLeft;
    interimListRef.current.scrollLeft =
      left + interimListRef.current.clientWidth;
  }, []);

  useEffect(() => {
    interimListScrollView();
  });

  return {
    interimListRef,
  };
};
